import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import roles from './roles/reducers';
import users from './users/reducers';
import invitedUsers from './invitedUsers/reducers';
import ui from './ui/reducers';
import teams from './teams/reducers';
import platformAdminAccess from './platformAdminAccess/reducers';

const config = (state = {}) => state;

export default combineReducers({
  config,
  invitedUsers,
  platformAdminAccess,
  roles,
  routing: routerReducer,
  teams,
  ui,
  users
});
