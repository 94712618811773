import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import { invokeArgsMapValues, pickKeys } from 'common/functional_helpers';
import RoutingTab from 'common/components/TabbedPanelInterface/RoutingTab';

import * as Selectors from '../../selectors';
import * as Actions from '../actions';
import RolesLoader from '../../roles/components/RolesLoader';

class InvitedUsersRoutingTab extends Component {
  static propTypes = {
    loadData: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.loadData();
  }

  render() {
    const props = pickProps(this.props);

    return (
      <RoutingTab key={props.to} {...props}>
        <RolesLoader />
        {I18n.t('users.navigation.invited_users')}
      </RoutingTab>
    );
  }
}

const mapDispatchToProps = {
  loadData: Actions.loadInvitedUsers
};
const propSelectors = {
  count: Selectors.getInvitedUserCount,
  loading: Selectors.getInvitedUsersLoading,
  to: Selectors.getInvitedUsersAdminPath
};
const pickProps = pickKeys(propSelectors);
const mapStateToProps = invokeArgsMapValues({
  ...propSelectors
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitedUsersRoutingTab);
