import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import { Result } from 'common/components/Autocomplete/components/Results/Result';
import 'common/components/Autocomplete/components/Results/results.scss';

export class TeamsAutocompleteResult extends Result {
  displayTitle = () => {
    const { team, matches } = this.props;
    const { screen_name: screenName } = team;

    const displayTitleFragments = [];

    // screen_name match
    const screenNameMatch = matches.find((match) => {
      return match.field === 'screen_name';
    });
    if (!isUndefined(screenNameMatch)) {
      displayTitleFragments.push(screenName.substring(0, get(screenNameMatch.offsets[0], 'start')));
      screenNameMatch.offsets.forEach((offset, index) => {
        displayTitleFragments.push(
          <span className="highlight" key={`name_${index}`}>
            {screenName.substring(offset.start, offset.length + offset.start)}
          </span>
        );

        const nextMatch = screenNameMatch.offsets[index + 1];
        const nextMatchStart = nextMatch ? nextMatch.start : undefined;
        displayTitleFragments.push(screenName.substring(offset.length + offset.start, nextMatchStart));
      });
    } else {
      displayTitleFragments.push(screenName);
    }

    return displayTitleFragments;
  };
}

TeamsAutocompleteResult.propTypes = {
  onChooseResult: PropTypes.func.isRequired,
  onResultFocusChanged: PropTypes.func.isRequired,
  onQueryChanged: PropTypes.func.isRequired,
  onResultsVisibilityChanged: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  matches: PropTypes.array.isRequired,
  team: PropTypes.object.isRequired,
  focused: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
};

export const renderTeamsAutocompleteResult = (
  result,
  index,
  focused,
  onChooseResult,
  onResultsVisibilityChanged,
  onResultFocusChanged,
  onQueryChanged
) => {
  const { matches, team } = result;

  return (
    <TeamsAutocompleteResult
      key={team.id}
      matches={matches}
      name={team.screen_name}
      team={team}
      index={index}
      focused={focused}
      onChooseResult={onChooseResult}
      onResultsVisibilityChanged={onResultsVisibilityChanged}
      onResultFocusChanged={onResultFocusChanged}
      onQueryChanged={onQueryChanged}
    />
  );
};
