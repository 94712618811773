import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tippy from '@tippyjs/react';
import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';

import I18n from 'common/i18n';
import DateFromNow from 'common/components/DateFromNow';
import ResultsTable from 'common/components/ResultsTable';
import SocrataIcon from 'common/components/SocrataIcon';
import { SORT_KEYS } from 'common/users-api';
import { getUserProfileLink } from 'common/js_utils/accounts';

import RolePicker from '../../roles/components/RolePicker';
import UsersDropdownButton from './UsersDropdownButton';
import UsersPager from './UsersPager';
import * as RolesActions from '../../roles/actions';
import * as Actions from '../actions';
import * as Selectors from '../../selectors';
import { UserPropType } from '../propTypes';
import { findRole, getRoleDisplayName } from '../../utils';
import CSVExportButton from './CSVExportButton';
import { FeatureFlags } from 'common/feature_flags';
import './UsersTable.scss';
import { generateCombinedTeamList } from '../helpers';

export class UsersTable extends Component {
  static propTypes = {
    loadingData: PropTypes.bool.isRequired,
    onRoleChange: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(UserPropType).isRequired,
    roleList: PropTypes.array.isRequired,
    manageableRoles: PropTypes.array.isRequired
  };

  renderLastActiveCell = (lastActive) => {
    return (
      <DateFromNow
        timestamp={lastActive}
        todayLabel={I18n.t('users.last_active.today')}
        unknownLabel={I18n.t('users.last_active.unknown')}
      />
    );
  };

  renderScreenNameCell = (screenName, { id, disabled, disabledOnOrganizationAt }) => {
    return (
      <span>
        <SocrataIcon name="user" />
        <a href={getUserProfileLink(id)}>{screenName}</a>
        {disabled && <span className="grey-badge">{I18n.t('users.disabled')}</span>}
        {!!disabledOnOrganizationAt && (
          <span className="grey-badge">{I18n.t('users.disabled_on_organization')}</span>
        )}
      </span>
    );
  };

  renderPendingActionStatus = (pendingRole) => {
    if (isUndefined(pendingRole)) return;

    return (
      <div className="pending-action-span">
        <div className="pending-action-cover">
          <span className="spinner-default spinner-medium" />
        </div>
      </div>
    );
  };

  renderRoleCell = (roleId, { id, pendingRole }) => {
    const { roleList, manageableRoles } = this.props;
    const onRoleChange = (newRole) => {
      const currentRole = getRoleDisplayName(findRole(roleId, roleList), roleList, I18n);
      const proposedRole = getRoleDisplayName(findRole(newRole, roleList), roleList, I18n);

      if (window.confirm(I18n.t('users.actions.confirm.change_role', { currentRole, proposedRole }))) {
        this.props.onRoleChange(id, newRole);
      }
    };
    const currentRoleId = isUndefined(pendingRole) ? roleId : pendingRole;
    const disabled = !manageableRoles.some((role) => role.id == currentRoleId);

    return (
      <div>
        <RolePicker roleId={currentRoleId} disabled={disabled} onRoleChange={onRoleChange} />
        {this.renderPendingActionStatus(pendingRole)}
      </div>
    );
  };

  renderActionsCell = (userId, { disabled, disabledOnOrganizationAt, roleId, screenName }) => {
    const { manageableRoles } = this.props;
    if (manageableRoles.some((role) => role.id == roleId)) {
      return (
        <UsersDropdownButton
          userId={userId}
          roleId={roleId}
          disabled={disabled}
          disabledOnOrganization={!!disabledOnOrganizationAt}
          screenName={screenName}
        />
      );
    } else {
      return null;
    }
  };

  renderTeamCell = (teamsMemberOf, teamsOwned) => {
    let teams = generateCombinedTeamList(teamsMemberOf, teamsOwned);
    let thisKey = new Date().valueOf().toString();

    if (teams != undefined) {
      if (teams.length == 1) {
        return <div key={thisKey}>{this.generateTeamCellContent(teams)}</div>;
      } else if (teams.length > 1) {
        return (
          <Tippy
            key={thisKey}
            className="teamCell"
            placement="left-start"
            maxWidth={250}
            content={this.generateTeamFlyoutContent(teams)}
            interactive={true}
            arrow={false}
            popperOptions={{
              modifiers: [
                {
                  name: 'computeStyles',
                  options: {
                    adaptive: false
                  }
                },
                {
                  name: 'offset',
                  options: {
                    // @ts-ignore
                    offset: ({ placement, reference, popper }) => {
                      if (placement === 'bottom-start') {
                        return [-1 * reference.width, 10];
                      }
                      if (placement === 'bottom-end') {
                        return [reference.width - 1, 10];
                      }
                      if (placement === 'top-start') {
                        return [-1 * reference.width, 10];
                      }
                      if (placement === 'top-end') {
                        return [reference.width - 1, 10];
                      } else {
                        return [0, 10];
                      }
                    }
                  }
                }
              ]
            }}
          >
            <span>{this.generateTeamCellContent(teams)}</span>
          </Tippy>
        );
      }
    }
  };

  generateTeamFlyoutContent = (teams) => {
    if (teams != undefined && teams.length > 1) {
      let teamList = [];
      // Create links to each team
      for (let i = 0; i < teams.length; i++) {
        teamList.push(this.createLink(teams[i].team_id, teams[i].team_name, i + 10));
      }

      let parentElement = React.createElement('div', {}, teamList);
      return parentElement;
    }
  };

  generateTeamCellContent = (teams) => {
    let thisKey = new Date().valueOf().toString();

    if (teams != null) {
      if (teams.length > 1) {
        return (
          <div className="teamCell" key={thisKey}>
            {teams.length} teams
          </div>
        );
      } else if (teams.length == 1) {
        return (
          <div className="teamCell" key={thisKey}>
            {this.createLink(teams[0].team_id, teams[0].team_name, teams[0].team_id)}
          </div>
        );
      } else {
        return;
      }
    }
  };

  createLink = (fourbyfour, teamName, id) => {
    let thisKey = [teamName, fourbyfour, id].join('-');

    const link = 'teams/' + fourbyfour;
    return (
      <div key={thisKey}>
        <a href={link}>{teamName}</a>
      </div>
    );
  };

  render() {
    const { loadingData, orderBy, onSort, sortDirection, users } = this.props;
    const noResultsMessage = I18n.t('users.no_results');
    const rowClassFunction = (user) => (user.disabled ? 'user-account-disabled' : 'user-account-active');
    return (
      <div>
        <ResultsTable
          data={users}
          rowKey="id"
          loadingData={loadingData}
          noResultsMessage={noResultsMessage}
          rowClassFunction={rowClassFunction}
          id="users-table"
        >
          <ResultsTable.Column
            isActive={orderBy === SORT_KEYS.SCREEN_NAME}
            sortDirection={sortDirection}
            onSort={() => onSort(SORT_KEYS.SCREEN_NAME)}
            header={I18n.t('users.headers.name')}
            dataIndex="screenName"
          >
            {this.renderScreenNameCell}
          </ResultsTable.Column>
          <ResultsTable.Column
            isActive={orderBy === SORT_KEYS.EMAIL}
            sortDirection={sortDirection}
            onSort={() => onSort(SORT_KEYS.EMAIL)}
            header={I18n.t('users.headers.email')}
            dataIndex="email"
          />
          {FeatureFlags.value('enable_teams') && (
            <ResultsTable.Column header={I18n.t('users.headers.teams')} dataIndex="teamsMemberOf">
              {this.renderTeamCell}
            </ResultsTable.Column>
          )}
          <ResultsTable.Column
            isActive={orderBy === SORT_KEYS.LAST_AUTHENTICATED_AT}
            sortDirection={sortDirection}
            onSort={() => onSort(SORT_KEYS.LAST_AUTHENTICATED_AT)}
            header={I18n.t('users.headers.last_sign_in')}
            dataIndex="lastAuthenticatedAt"
          >
            {this.renderLastActiveCell}
          </ResultsTable.Column>
          <ResultsTable.Column
            isActive={orderBy === SORT_KEYS.ROLE_NAME}
            sortDirection={sortDirection}
            onSort={() => onSort(SORT_KEYS.ROLE_NAME)}
            header={I18n.t('users.headers.role')}
            dataIndex="roleId"
            dataClassName="role-picker-cell"
          >
            {this.renderRoleCell}
          </ResultsTable.Column>
          <ResultsTable.Column
            header={I18n.t('users.headers.actions')}
            dataIndex="id"
            dataClassName="action-menu-cell"
          >
            {this.renderActionsCell}
          </ResultsTable.Column>
        </ResultsTable>
        <div className="results-list-footer">
          <UsersPager />
          <CSVExportButton classExportType="user" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserId: Selectors.getCurrentUserId(state),
  loadingData: Selectors.getUsersLoadingData(state),
  orderBy: Selectors.getUsersOrderBy(state),
  sortDirection: Selectors.getUsersSortDirection(state),
  users: Selectors.getUsers(state),
  roleList: Selectors.getRoles(state),
  manageableRoles: Selectors.getManageableRoles(state)
});

const mapDispatchToProps = {
  onRoleChange: RolesActions.changeUserRole,
  onSort: Actions.sortUserColumn
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
