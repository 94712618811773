import React, { FunctionComponent, useRef, useState } from 'react';

import CoreTeamsApi from 'common/core/teams';
import { CreateOrUpdateTeamRequestParameters, Team } from '@socrata/core-teams-api';

import I18n from 'common/i18n';
import { reload as windowLocationReload, assign as windowLocationAssign } from 'common/window_location';
import FeatureFlags from 'common/feature_flags';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import { showSuccessToastNow, showErrorToastNow } from 'common/components/ToastNotification/Toastmaster';
import Button, { VARIANTS } from 'common/components/Button';
import Form from 'common/components/Forms/Form';
import Input from 'common/components/Forms/Input';
import Flyout from 'common/components/Flyout';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

interface EditTeamModalProps {
  /**
   * If we're editing an existing team, this should be passed in.
   * If this is undefined, it's assumed that we're creating a new team.
   */
  currentTeam?: Team;

  /** Function to call when closing modal */
  dismissModal: () => void;

  /**
   * Function to call that returns the proper path to go to for viewing a specific team.
   * Used when creating a new time to figure out where to redirect to to view it.
   */
  getTeamViewPath?: (teamId: string) => string;
}

/**
 * Uses the core API to create/edit a team
 * @param team The team to create/update. If the given team has no `id` then we create, otherwise we update.
 */
const createOrUpdateTeam = async (team: Partial<Team>): Promise<Team> => {
  const createOrUpdateTeamRequestParameters: CreateOrUpdateTeamRequestParameters = {
    screenName: team.screenName!, // we know this exists because we validate before calling this function

    // this will be empty strings if the input is empty, but we want to not include them at all if they stay empty
    // this uses the fact that an empty string is falsy in JavaScript
    description: team.description,
    email: team.email
  };

  if (team.id) {
    // if we have an id, we're updating a team
    return await CoreTeamsApi.updateTeam({
      teamId: team.id,
      createOrUpdateTeamRequestParameters
    });
  } else {
    // if we don't have an id, we're creating a new team
    return await CoreTeamsApi.createTeam({
      createOrUpdateTeamRequestParameters
    });
  }
};

const EditTeamModal: FunctionComponent<EditTeamModalProps> = ({
  currentTeam,
  dismissModal,
  getTeamViewPath
}) => {
  const enableTeamsFeatureFlag = FeatureFlags.value('enable_teams');

  const [busy, setBusy] = useState(false);

  const [screenName, setScreenName] = useState(currentTeam?.screenName || '');
  const [screenNameError, setScreenNameError] = useState<string | undefined>(undefined);
  const screenNameInputRef = useRef<HTMLInputElement>(null);

  const [description, setDescription] = useState(currentTeam?.description || '');

  const [email, setEmail] = useState(currentTeam?.email || '');
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // screenName is required
    if (!screenName || screenName.length === 0) {
      setScreenNameError(I18n.t('users.errors.no_team_name_provided'));

      // focus on the input for screen readers
      screenNameInputRef.current?.focus();
      return;
    }

    setBusy(true);

    try {
      const team = await createOrUpdateTeam({ screenName, description, email, id: currentTeam?.id });

      // if we're editing an existing team, we reload the page
      if (currentTeam) {
        showSuccessToastNow(I18n.t('users.edit_team.edit_team_success_notification'));
        // If we're already showing the edit team modal, then redirect to the pathname to avoid reopening it
        if (window.location.search.includes('edit=true')) windowLocationAssign(window.location.pathname);
        else {
          windowLocationReload();
        }

        return;
      }

      // if we're creating a new team, we redirect to it
      showSuccessToastNow(I18n.t('users.edit_team.create_team_success_notification'));
      if (getTeamViewPath) {
        windowLocationAssign(getTeamViewPath(team.id));
      }
    } catch (error) {
      const errorJson = await error.json();

      console.error('Error saving team!', errorJson);

      if (errorJson.code === 'USER.EMAIL_ALREADY_TAKEN') {
        setEmailError(I18n.t('users.errors.email_taken', { value: email }));
        emailInputRef.current?.focus();
      } else if (errorJson.code === 'USER.EMAIL_INVALID') {
        setEmailError(I18n.t('users.errors.invalid_email', { value: email }));
        emailInputRef.current?.focus();
      } else {
        // unknown error; show an error toast
        showErrorToastNow(
          <span dangerouslySetInnerHTML={{ __html: I18n.t('users.errors.null_error_code_html') }} />
        );
      }

      setBusy(false);
    }
  };

  return (
    <Form className="add-team" onSubmit={handleFormSubmit}>
      <Modal onDismiss={dismissModal}>
        <ModalHeader
          showCloseButton={false}
          title={
            currentTeam
              ? I18n.t('users.edit_team.edit_team_title')
              : I18n.t('users.add_new_team.add_team_title')
          }
        />
        <ModalContent>
          <Input
            required
            label={I18n.t('users.add_new_team.name_label')}
            placeholder={I18n.t('users.add_new_team.name_placeholder')}
            name="team-screen-name"
            errorMessage={screenNameError}
            valid={screenNameError === undefined}
            value={screenName}
            ref={screenNameInputRef}
            onChange={(e) => {
              setScreenName(e.target.value);
              setScreenNameError(undefined);
            }}
          />
          <Input
            label={I18n.t('users.add_new_team.description_label')}
            placeholder={I18n.t('users.add_new_team.description_placeholder')}
            name="team-description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {enableTeamsFeatureFlag && (
            <Input
              label={I18n.t('users.add_new_team.email_label')}
              placeholder={I18n.t('users.add_new_team.email_placeholder')}
              name="team-email"
              value={email}
              errorMessage={emailError}
              valid={emailError === undefined}
              ref={emailInputRef}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(undefined);
              }}
            >
              <Flyout text={I18n.t('users.team_email_flyout_tooltip_popover')} id="team_email_flyout">
                <SocrataIcon name={IconName.Info} />
              </Flyout>
            </Input>
          )}
          <div>
            <sup>*</sup> {I18n.t('core.forms.required_field')}
          </div>
        </ModalContent>
        <ModalFooter>
          <Button className="cancel-button" disabled={busy} onClick={dismissModal}>
            {I18n.t('users.add_new_users.cancel')}
          </Button>
          <Button
            type="submit"
            variant={VARIANTS.PRIMARY}
            className="add-user-confirm"
            disabled={busy}
            busy={busy}
          >
            {/* Existence of a currentTeam means we're editing it, otherwise we're creating a new team */}
            {currentTeam ? I18n.t('users.edit_team.save') : I18n.t('users.add_new_team.add_team')}
          </Button>
        </ModalFooter>
      </Modal>
    </Form>
  );
};

export default EditTeamModal;
