import { combineReducers } from 'redux';
import { combineSelectors } from 'combine-selectors-redux';
import omit from 'lodash/fp/omit';

import teams, * as teamsSelectors from './reducers/teamsReducer';
import ui, * as uiSelectors from './reducers/uiReducer';
import selectUsersByEmailList from 'common/components/AccessManager/components/SelectUsersByEmailList/SelectUsersByEmailListReducer';

const omitInitialState = omit('initialState');
export const selectors = combineSelectors({
  ui: omitInitialState(uiSelectors),
  teams: omitInitialState(teamsSelectors)
});

export default combineReducers({
  teams,
  ui,
  selectUsersByEmailList
});
