import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';

import RolePicker from '../../roles/components/RolePicker';
import * as RoleActions from '../../roles/actions';
import * as Selectors from '../../selectors';

class UserRoleFilter extends Component {
  static propTypes = {
    roleId: PropTypes.string
  };

  render() {
    const { roleId, onRoleChange } = this.props;

    const initialOption = {
      title: I18n.t('users.roles.all'),
      value: 'all'
    };

    return (
      <div className="user-filter">
        <div className="user-filter-label">Filter by</div>
        <RolePicker
          initialOption={initialOption}
          roleId={roleId ? roleId : 'all'}
          onRoleChange={onRoleChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roleId: Selectors.getUserRoleFilter(state)
  };
};

const mapDispatchToProps = {
  onRoleChange: RoleActions.changeUserRoleFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleFilter);
