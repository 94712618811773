import React, { FunctionComponent } from 'react';
import {
  TeamEligibleForDeletionResponse,
  TeamEligibleForDeletionResponseReasonsEnum
} from '@socrata/core-teams-api';

import GenericError from './GenericError';
import TeamOwnsAssetsWarning from './TeamOwnsAssetsWarning';

interface CannotDeleteTeamReasonsProps {
  /** UID (4x4) of the team we're trying to delete */
  id: string;

  /** Response from eligible for deletion API */
  response?: TeamEligibleForDeletionResponse;
}

/** Given a list of reasons that a team cannot be deleted, renders various warnings. */
const CannotDeleteTeamReasons: FunctionComponent<CannotDeleteTeamReasonsProps> = ({ id, response }) => {
  const reasons: JSX.Element[] = [];

  // if there are no reasons but the team still cannot be deleted, we show a generic message
  if (!response?.reasons || response.reasons.length === 0) {
    reasons.push(<GenericError key="empty-error" />);
  }

  // currently, the only reason a team cannot be deleted is if it owns assets
  // we may add more reasons in the future!
  response?.reasons?.forEach((reason) => {
    switch (reason) {
      case TeamEligibleForDeletionResponseReasonsEnum.TeamOwnsAssets:
        reasons.push(<TeamOwnsAssetsWarning key="team-owns-assets" id={id} />);
        break;
      default:
        // this is just a fallback in case we add more reasons in the future,
        // but forget to update this or changes to it are delayed by a release
        reasons.push(<GenericError key="unknown-error" />);
        break;
    }
  });

  return <>{reasons}</>;
};

export default CannotDeleteTeamReasons;
