// Imports
// --------------------------------------------------------------------------------

// Third-Party Imports
// ----------------------------------------------------------------------
import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import {
  ForgeIcon,
  ForgeIconButton,
  ForgeTab,
  ForgeTabBar,
  ForgeTable,
  ForgeView,
  ForgeViewSwitcher
} from '@tylertech/forge-react';
import get from 'lodash/fp/get';

// Common Imports
// ----------------------------------------------------------------------
import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import type { CatalogUserOrTeam, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';
import UserSearch, { UserSearchProps } from 'common/components/UserSearch';
import SelectUsersByEmailList from 'common/components/AccessManager/components/SelectUsersByEmailList';
import {
  SelectUsersByEmailListAcceptedProps,
  SelectUsersByEmailListProps
} from 'common/components/AccessManager/components/SelectUsersByEmailList/SelectUsersByEmailListTypes';
import AlertList from 'common/components/AlertList';
import { AlertListAlertProps } from 'common/components/AlertList/AlertListTypes';
import JsxToHtmlElementService from 'common/tyler_forge/js_utilities/jsxToHtmlElementService/JsxToHtmlElementService';

// Local Imports
// ----------------------------------------------------------------------
import * as Selectors from '../../../selectors.js';
import * as Actions from '../../actions';

// Variables
// --------------------------------------------------------------------------------
const i18nScope = 'shared.access_manager.teams.add_team_members';

// Setup Props
// --------------------------------------------------------------------------------
interface StateProps {
  // Modal Props
  // -------------------------------------
  disableModal: boolean;
  errors: any[];
  showModal: boolean;
  teamId: string;

  // Selected Users Props
  // -------------------------------------
  selectedUsers: CatalogUserOrTeam[];

  // User Search Props
  // -------------------------------------
  currentQuery: string;
  maxSelectedUsers: number;
  noResultsMessage: string;
  placeHolderText: string;
  results: any[];
  renderSelectedUserPills: boolean;

  // Select Users by Email List Props
  // -------------------------------------
  selectUserAlerts: AlertListAlertProps[];
  teamMemberIds: string[];
}

interface DispatchProps {
  // Modal Props
  // -------------------------------------
  cancelModal: () => void;
  submitModal: () => void;

  // Selected Users Props
  // -------------------------------------
  addToSelectedUsers: (users?: UsersCatalogSearchResults[] | UsersCatalogSearchResults) => void;
  removeFromSelectedUsers: (user: any) => void;

  // User Search Props
  // -------------------------------------
  userSearchQueryChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  userSearchClearQuery: () => void;

  // Select Users by Email List Props
  // -------------------------------------
  addToSelectUserAlerts: (alert: AlertListAlertProps[] | AlertListAlertProps) => void;
  removeFromSelectUserAlerts: (alert: AlertListAlertProps) => void;
  clearSelectUserAlerts: () => void;
}

type AddTeamMembersModalProps = StateProps &
  DispatchProps &
  SelectUsersByEmailListAcceptedProps &
  UserSearchProps;

// Class Definition
// --------------------------------------------------------------------------------
export class AddTeamMembersModal extends Component<AddTeamMembersModalProps> {
  // Attributes
  // --------------------------------------------------------------------
  currentViewSwitcherIndex = 0;
  viewSwitcherViews: Array<any>;
  jsxToHtmlElementService: JsxToHtmlElementService;

  // Methods
  // --------------------------------------------------------------------

  constructor(props: AddTeamMembersModalProps) {
    super(props);

    this.jsxToHtmlElementService = new JsxToHtmlElementService();

    this.viewSwitcherViews = [
      {
        label: I18n.t('select_by_email_list.labels.tab', { scope: i18nScope }),
        renderer: this.renderAddUsersByEmailList,
        key: 0
      },
      {
        label: I18n.t('user_search.labels.tab', { scope: i18nScope }),
        renderer: this.renderUserSearch,
        key: 0
      }
    ];
  }

  componentWillUnmount = () => {
    this.jsxToHtmlElementService.deleteAll();
  };

  // Helpers
  // ----------------------------------------------------------

  // Data Structures & Management
  // ----------------------------------------------------------
  updateViewIndex = (event: any) => {
    const viewSwitcherComponent: any = document.getElementById('ForgeViewSwitcher');

    if (viewSwitcherComponent != null) {
      this.currentViewSwitcherIndex = event.detail.index;
      viewSwitcherComponent.index = event.detail.index;
    }
  };

  getModalProps = () => {
    return {
      className: 'add-team-member-v2',
      fullScreen: false,
      onDismiss: () => {}
    };
  };

  getHeaderProps = () => {
    const { cancelModal } = this.props;

    return {
      showCloseButton: false,
      title: I18n.t('modal_header.title', { scope: i18nScope }),
      onDismiss: cancelModal
    };
  };

  getTableProps = () => {
    const { selectedUsers } = this.props;

    return {
      data: selectedUsers,
      selectKey: 'id',
      fixedHeaders: true,
      columnConfigurations: [
        {
          header: I18n.t('selected_users.headers.name', { scope: i18nScope }),
          property: 'screen_name',
          sortable: true
        },
        {
          header: I18n.t('selected_users.headers.email', { scope: i18nScope }),
          property: 'email',
          sortable: true
        },
        {
          // header: I18n.t('selected_users.headers.actions', { scope: i18nScope }),
          property: 'actions',
          align: 'right',
          template: this.getSelectedUsersActionButtons
        }
      ]
    };
  };

  // Template Generating
  // ----------------------------------------------------------
  createRemoveFromSelectedUsersButton = (rowData: any) => {
    const { removeFromSelectedUsers }: any = this.props;

    return (
      <div>
        <ForgeIconButton>
          <button
            className="tyler-icons"
            title={I18n.t('selected_users.actions.remove', { scope: i18nScope })}
            onClick={() => removeFromSelectedUsers({ id: rowData.id })}
          >
            <ForgeIcon name="remove_circle" />
          </button>
        </ForgeIconButton>
      </div>
    );
  };

  // prettier-ignore
  createSelectedUsersActionButtons = (rowIndex: number, div: any, rowData: any) => {
    let response = (<></>);

    if (rowData) {
      // More actions can be added here within the response
      response = (
        <>
          {this.createRemoveFromSelectedUsersButton(rowData)}
        </>
      );
    }

    return response;
  };

  getSelectedUsersActionButtons = (rowIndex: number, div: any, rowData: any) => {
    let response;

    if (rowData) {
      response = this.jsxToHtmlElementService.wrapJsx(
        this.createSelectedUsersActionButtons(rowIndex, div, rowData),
        `add_team_members_selected_user_${rowData.id}`
      );
    }

    return response;
  };

  // Renderers
  // ----------------------------------------------------------
  renderUserSearch = () => {
    const {
      addToSelectedUsers,
      currentQuery,
      maxSelectedUsers,
      noResultsMessage,
      placeHolderText,
      results,
      removeFromSelectedUsers,
      selectedUsers,
      userSearchQueryChanged,
      userSearchClearQuery
    } = this.props;
    const userSearchProps = {
      addToSelectedUsers,
      currentQuery,
      includeTeamsInHelpText: false,
      maxSelectedUsers,
      noResultsMessage,
      placeHolderText: placeHolderText || I18n.t('user_search.labels.placeholder', { scope: i18nScope }),
      results,
      removeFromSelectedUsers,
      selectedUsers,
      renderSelectedUserPills: false,
      userSearchQueryChanged,
      userSearchClearQuery,
      preventBackspaceClearsSelectedUsers: true
    };

    return (
      <div>
        <UserSearch {...userSearchProps} />
      </div>
    );
  };

  renderAddUsersByEmailList = () => {
    const {
      selectedUsers,
      addToSelectedUsers,
      addToSelectUserAlerts,
      removeFromSelectUserAlerts,
      clearSelectUserAlerts,
      teamMemberIds
    } = this.props;
    const addUsersByEmailListProps = {
      selectedUsers,
      addToSelectedUsers,
      addToSelectUserAlerts,
      removeFromSelectUserAlerts,
      clearSelectUserAlerts,
      filterUserIdsFromResults: teamMemberIds,
      placeHolderText: I18n.t('select_by_email_list.labels.placeholder', { scope: i18nScope }),
      maxEmails: 100,
      getSelectUsersByEmailListState: get('teams.selectUsersByEmailList')
    } as unknown as SelectUsersByEmailListProps;

    return (
      <div>
        <SelectUsersByEmailList {...addUsersByEmailListProps} />
      </div>
    );
  };

  renderViewSwitcherTabs = (view: any, i: number) => {
    if (i === this.currentViewSwitcherIndex) {
      return (
        <ForgeTab aria-selected="true" key={i}>
          {view.label}
        </ForgeTab>
      );
    } else {
      return (
        <ForgeTab key={i} tabIndex={0}>
          {view.label}
        </ForgeTab>
      );
    }
  };

  renderViewSwitcherViews = (view: any, i: number) => {
    return <ForgeView key={i}>{view.renderer()}</ForgeView>;
  };

  render() {
    const { cancelModal, disableModal, submitModal, selectUserAlerts, showModal, selectedUsers } = this.props;
    const modalProps = this.getModalProps();
    const headerProps = this.getHeaderProps();
    const tableProps = this.getTableProps();
    const disableSubmit = disableModal || !selectedUsers?.length;

    if (showModal) {
      return (
        <Modal {...modalProps}>
          <ModalHeader {...headerProps} />

          <ModalContent>
            <ForgeTabBar
              on-forge-tab-bar-activate={this.updateViewIndex}
              active-tab={this.currentViewSwitcherIndex}
            >
              {this.viewSwitcherViews.map(this.renderViewSwitcherTabs)}
            </ForgeTabBar>

            <ForgeViewSwitcher id="ForgeViewSwitcher">
              {this.viewSwitcherViews.map(this.renderViewSwitcherViews)}
            </ForgeViewSwitcher>

            <div className="modal-content-scroll">
              <AlertList alerts={selectUserAlerts} />
              <ForgeTable {...tableProps} />
            </div>
          </ModalContent>

          <ModalFooter>
            <div>
              <Button className="cancel-button" onClick={cancelModal} disabled={disableModal}>
                {I18n.t('modal_buttons.cancel', { scope: i18nScope })}
              </Button>
              <Button
                type="submit"
                variant={VARIANTS.PRIMARY}
                className="add-user-confirm"
                disabled={disableSubmit}
                busy={disableModal}
                onClick={submitModal}
              >
                {I18n.t('modal_buttons.submit', { scope: i18nScope })}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any): StateProps => ({
  ...Selectors.getTeamUserSearch(state),
  disableModal: Selectors.getDisableAddTeamMembersModal(state),
  errors: Selectors.getTeamAddMemberErrors(state),
  results: Selectors.getTeamUserSearchResults(state),
  selectUserAlerts: Selectors.getTeamSelectUserAlerts(state),
  showModal: Selectors.getShowAddTeamMembersModal(state),
  teamId: Selectors.getTeamFormId(state),
  teamMemberIds: Selectors.getCurrentTeamMemberIds(state),
  selectedUsers: Selectors.getTeamAddMemberSelectedUsers(state) as CatalogUserOrTeam[]
});

const mapDispatchToProps: DispatchProps = {
  cancelModal: Actions.cancelAddTeamMembersModal,
  submitModal: Actions.submitAddTeamMembersModal,
  addToSelectedUsers: Actions.addToSelectedUsers,
  removeFromSelectedUsers: Actions.removeFromSelectedUsers,
  addToSelectUserAlerts: Actions.addToSelectUserAlerts,
  removeFromSelectUserAlerts: Actions.removeFromSelectUserAlerts,
  clearSelectUserAlerts: Actions.clearSelectUserAlerts,
  userSearchQueryChanged: (event: ChangeEvent<HTMLInputElement>) =>
    Actions.userSearchQueryChanged(event.target.value, { only: 'site_members' }),
  userSearchClearQuery: () => Actions.userSearchClearQuery()
};

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(AddTeamMembersModal);
