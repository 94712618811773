import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import { invokeArgsMapValues, pickKeys } from 'common/functional_helpers';
import RoutingTab from 'common/components/TabbedPanelInterface/RoutingTab';

import * as Selectors from '../../selectors';
import * as Actions from '../actions';

class PlatformAdminAccessRoutingTab extends Component {
  static propTypes = {
    loadData: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.loadData();
  }

  render() {
    const props = pickProps(this.props);

    return (
      <RoutingTab key={props.to} {...props}>
        {I18n.t('users.platform_admin_access.title')}
      </RoutingTab>
    );
  }
}

const mapDispatchToProps = {
  loadData: Actions.loadPlatformAdmins
};
const propSelectors = {
  loading: Selectors.PlatformAdminAccess.getLoadingData,
  to: Selectors.getPlatformAdminAccessPath
};
const pickProps = pickKeys(propSelectors);
const mapStateToProps = invokeArgsMapValues(propSelectors);

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(
  PlatformAdminAccessRoutingTab
);
