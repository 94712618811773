import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import { invokeArgsMapValues } from 'common/functional_helpers';

import * as Selectors from '../../selectors';
import * as Actions from '../actions';
import AccessTimeChooser from './AccessTimeChooser';

export class GrantPlatformAdminAccessModal extends Component {
  static propTypes = {
    handleCancelModal: PropTypes.func,
    disableModal: PropTypes.bool,
    showModal: PropTypes.bool.isRequired,
    handleSubmitModal: PropTypes.func
  };

  handleEmailChange = (event) => {
    const { changePlatformAdminEmail } = this.props;
    changePlatformAdminEmail(event.target.value);
  };

  handleSubmit = (event) => {
    const { handleSubmitModal } = this.props;
    event.preventDefault();
    handleSubmitModal();
  };

  render() {
    const { accessTime, busy, changeAccessTime, email, showModal, disableModal, handleCancelModal } =
      this.props;

    const modalProps = {
      fullScreen: false,
      onDismiss: () => {}
    };
    const modalTitle = I18n.t('users.platform_admin_access.grant_platform_admin_access');

    const headerProps = {
      showCloseButton: false,
      title: modalTitle,
      onDismiss: handleCancelModal
    };
    return (
      showModal && (
        <form className="grant-admin-access-form" onSubmit={this.handleSubmit}>
          <Modal {...modalProps}>
            <ModalHeader {...headerProps} />

            <ModalContent>
              <label className="block-label" htmlFor="platform-admin-email">
                {I18n.t('users.headers.email')}:
              </label>
              <input
                className="grant-admin-access-input text-input text-area"
                id="platform-admin-email"
                onChange={this.handleEmailChange}
                ref={(ref) => (this.email = ref)}
                required
                type="email"
                value={email}
              />
              <AccessTimeChooser onChange={changeAccessTime} accessTime={accessTime} />
            </ModalContent>

            <ModalFooter>
              <div>
                <Button className="cancel-button" onClick={handleCancelModal} disabled={disableModal}>
                  {I18n.t('users.add_new_users.cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="grant-admin-access-submit"
                  disabled={disableModal}
                  busy={busy}
                >
                  {I18n.t('users.platform_admin_access.grant_access')}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </form>
      )
    );
  }
}

const mapStateToProps = invokeArgsMapValues({
  accessTime: Selectors.PlatformAdminAccess.getAccessTime,
  busy: Selectors.PlatformAdminAccess.getGrantingPlatformAdminAccess,
  disableModal: Selectors.PlatformAdminAccess.getDisableModal,
  email: Selectors.PlatformAdminAccess.getEmail,
  showModal: Selectors.PlatformAdminAccess.showAddPlatformAdminAccessModal
});

const mapDispatchToProps = {
  changeAccessTime: Actions.changeAccessTime,
  changePlatformAdminEmail: Actions.changePlatformAdminEmail,
  handleCancelModal: Actions.cancelAddPlatformAdminAccessModal,
  handleSubmitModal: Actions.submitAddPlatformAdminAccessModal
};

export default connect(mapStateToProps, mapDispatchToProps)(GrantPlatformAdminAccessModal);
