import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';

import * as Actions from '../actions';
import * as Selectors from '../../selectors';

class UserStatusFilter extends Component {
  static propTypes = {
    roleId: PropTypes.string
  };

  render() {
    const { status, onChange } = this.props;

    const options = [
      { title: I18n.t('users.status.all'), value: Actions.STATUS_FILTERS.ALL },
      { title: I18n.t('users.status.disabled'), value: Actions.STATUS_FILTERS.DISABLED },
      { title: I18n.t('users.status.enabled'), value: Actions.STATUS_FILTERS.ENABLED }
    ];

    return (
      <div className="user-filter">
        <div className="user-filter-label">{I18n.t('users.status.label')}</div>
        <Dropdown
          onSelection={(selection) => onChange(selection.value)}
          options={options}
          size="medium"
          value={status}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    status: Selectors.getUserStatusFilter(state)
  };
};

const mapDispatchToProps = {
  onChange: Actions.changeUserStatusFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(UserStatusFilter);
