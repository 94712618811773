import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import createStore from './createStore';

const store = createStore();

const render = (Component) => {
  ReactDOM.render(<Component store={store} locale={serverConfig.locale} />, document.querySelector('#app'));
};

render(App);
