import { makeZeroBasedPageFromPager } from '../utils';
import * as RolesActions from '../roles/actions';
import * as Actions from './actions';
import { SORT_DIRECTION, SORT_KEYS } from 'common/users-api';

import { isNotNil } from 'common/functional_helpers';

import add from 'lodash/fp/add';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';

export const initialState = {
  addUsersForm: { emails: '', roleId: null, errors: [] },
  filterRoleId: null,
  loadingData: true,
  orderBy: SORT_KEYS.SCREEN_NAME,
  resultCount: 0,
  query: null,
  searchResultCount: undefined,
  sortDirection: SORT_DIRECTION.ASC,
  statusFilter: Actions.STATUS_FILTERS.ENABLED,
  users: [],
  zeroBasedPage: 0
};

const handleLoadUsers = (state) => ({
  ...state,
  loadingData: true
});

const handleLoadUsersSuccess = (state, { users, resultCount }) => ({
  ...state,
  loadingData: false,
  users: mapUsers(users),
  resultCount
});

const handleLoadUsersFailure = (state) => ({
  ...state,
  loadingData: false
});

const mapUser = (userId, propertiesToChange) => (user) =>
  user.id === userId ? { ...user, ...propertiesToChange } : user;

const handleChangeUserRole = (state, { userId, newRole }) => ({
  ...state,
  users: state.users.map(mapUser(userId, { pendingRole: newRole }))
});

const handleChangeUserRoleSuccess = (state, { userId, newRole, newRoleName }) => ({
  ...state,
  users: state.users.map(mapUser(userId, { pendingRole: undefined, roleId: newRole, roleName: newRoleName }))
});

const handleChangeUserRoleFailure = (state, { userId }) => ({
  ...state,
  users: state.users.map(mapUser(userId, { pendingRole: undefined }))
});

const handleRemoveUserRole = (state, { userId }) => ({
  ...state,
  users: state.users.map(mapUser(userId, { removingRole: true }))
});

const handleDisableUserSuccess = (state, { userId }) => ({
  ...state,
  users: state.users.map(mapUser(userId, { disabled: true, disabledAt: new Date().toString() }))
});

const handleEnableUserSuccess = (state, { userId }) => ({
  ...state,
  users: state.users.map(mapUser(userId, { disabled: false, disabledAt: null }))
});

const handleRemoveUserRoleSuccess = (state, { userId }) => ({
  ...state,
  users: state.users.filter((user) => user.id !== userId)
});

const handleRemoveUserRoleFailure = (state, { userId }) => ({
  ...state,
  users: state.users.map(mapUser(userId, { removingRole: undefined }))
});

const handleQueryBasedUserSearch = (state, { query }) => ({
  ...state,
  orderBy: isEmpty(query) ? SORT_KEYS.SCREEN_NAME : SORT_KEYS.RELEVANCE,
  query
});

const handleInteractionBasedUserSearch = (state) => ({
  ...state,
  loadingData: true
});

const handleUserSearchSuccess = (state, { users, resultCount }) => ({
  ...state,
  loadingData: false,
  searchResultCount: resultCount,
  users: mapUsers(users)
});

const handleUserSearchFailure = (state) => ({
  ...state,
  loadingData: false,
  searchResultCount: undefined,
  users: []
});

const handleGotoPage = (state, { page }) => ({
  ...state,
  zeroBasedPage: makeZeroBasedPageFromPager(page)
});

const handleColumnSort = (state, { columnKey }) => {
  const orderBy = getOrderBy(state);
  const sortDirection = getSortDirection(state);
  const zeroBasedPage = initialState.zeroBasedPage;
  if (orderBy !== columnKey) {
    return { ...state, orderBy: columnKey, sortDirection: initialState.sortDirection, zeroBasedPage };
  } else {
    return {
      ...state,
      sortDirection: SORT_DIRECTION[Object.values(SORT_DIRECTION).filter((val) => val !== sortDirection)[0]],
      zeroBasedPage
    };
  }
};

const handleChangeAddUsersForm = (state, { emails, roleId }) => ({
  ...state,
  addUsersForm: { emails, roleId, errors: [] }
});

const handleSetAddUsersFormErrors = (state, { errors }) => ({
  ...state,
  addUsersForm: { ...state.addUsersForm, errors }
});

const handleClearAddUsersForm = (state) => ({
  ...state,
  addUsersForm: { ...initialState.addUsersForm }
});

const handleUserAutocomplete = (state, { query }) => ({
  ...state,
  query
});

const handleClearUserAutocomplete = (state) => ({
  ...state,
  orderBy: initialState.orderBy,
  sortDirection: initialState.sortDirection,
  query: initialState.query,
  zeroBasedPage: initialState.zeroBasedPage
});

const handleChangeUserStatusFilter = (state, { status }) => ({
  ...state,
  statusFilter: status
});

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RolesActions.CHANGE_USER_ROLE:
      return handleChangeUserRole(state, payload);
    case RolesActions.CHANGE_USER_ROLE_SUCCESS:
      return handleChangeUserRoleSuccess(state, payload);
    case RolesActions.CHANGE_USER_ROLE_FAILURE:
      return handleChangeUserRoleFailure(state, payload);

    case RolesActions.REMOVE_USER_ROLE:
      return handleRemoveUserRole(state, payload);
    case RolesActions.REMOVE_USER_ROLE_SUCCESS:
      return handleRemoveUserRoleSuccess(state, payload);
    case RolesActions.REMOVE_USER_ROLE_FAILURE:
      return handleRemoveUserRoleFailure(state, payload);

    case Actions.LOAD_USERS:
      return handleLoadUsers(state, payload);
    case Actions.LOAD_USERS_SUCCESS:
      return handleLoadUsersSuccess(state, payload);
    case Actions.LOAD_USERS_FAILURE:
      return handleLoadUsersFailure(state, payload);

    case Actions.QUERY_BASED_USER_SEARCH:
      return handleQueryBasedUserSearch(state, payload);

    case Actions.INTERACTION_BASED_USER_SEARCH:
      return handleInteractionBasedUserSearch(state);
    case Actions.USER_SEARCH_SUCCESS:
      return handleUserSearchSuccess(state, payload);
    case Actions.USER_SEARCH_FAILURE:
      return handleUserSearchFailure(state, payload);

    case Actions.USER_AUTOCOMPLETE:
      return handleUserAutocomplete(state, payload);

    case Actions.CLEAR_USER_AUTOCOMPLETE:
      return handleClearUserAutocomplete(state);

    case Actions.CHANGE_ADD_USERS_FORM:
      return handleChangeAddUsersForm(state, payload);

    case Actions.SET_ADD_USERS_FORM_ERRORS:
      return handleSetAddUsersFormErrors(state, payload);

    case Actions.GOTO_USER_PAGE:
      return handleGotoPage(state, payload);

    case Actions.SORT_USER_COLUMN:
      return handleColumnSort(state, payload);

    case Actions.CLEAR_ADD_USERS_FORM:
      return handleClearAddUsersForm(state);

    case Actions.ENABLE_USER_SUCCESS:
      return handleEnableUserSuccess(state, payload);

    case Actions.DISABLE_USER_SUCCESS:
      return handleDisableUserSuccess(state, payload);

    case Actions.CHANGE_USER_STATUS_FILTER:
      return handleChangeUserStatusFilter(state, payload);

    default:
      return state;
  }
};

export default reducer;

export const mapUsers = map((u) => ({ ...u, disabled: getDisabledFromUser(u) }));
export const getZeroBasedPage = get('zeroBasedPage');
export const getCurrentPage = flow(getZeroBasedPage, add(1));
export const getResultCount = get('resultCount');
export const getUsers = get('users');
export const getOrderBy = get('orderBy');
export const getSortDirection = get('sortDirection');
export const getLoadingData = get('loadingData');
export const getScreenNameFromUser = get('screen_name');
export const getEmailFromUser = get('email');
export const getIdFromUser = get('id');
export const getDisabledFromUser = flow(get('disabledAt'), isNotNil);
export const getSearchResultCount = get('searchResultCount');
export const getQuery = get('query');

export const getAddUsersForm = get('addUsersForm');
export const getAddUsersFormEmails = flow(getAddUsersForm, get('emails'));
export const getAddUsersFormRoleId = flow(getAddUsersForm, get('roleId'));
export const getAddUsersFormErrors = flow(getAddUsersForm, get('errors'));
export const getUserStatusFilter = get('statusFilter');
