import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';

import { getRoleDisplayName } from '../../utils';
import * as Selectors from '../../selectors';
import { RolePropType } from '../propTypes';

export class RolePicker extends Component {
  static propTypes = {
    availableRoles: PropTypes.arrayOf(RolePropType).isRequired,
    roleId: PropTypes.string,
    onRoleChange: PropTypes.func.isRequired,
    initialOption: PropTypes.object,
    placeholder: PropTypes.string
  };

  render() {
    const { availableRoles, onRoleChange, roleId, initialOption, placeholder, disabled } = this.props;

    if (initialOption) {
      availableRoles.unshift(initialOption);
    }

    return (
      <Dropdown
        disabled={disabled}
        onSelection={(selection) => onRoleChange(selection.value)}
        options={availableRoles}
        size="medium"
        value={`${roleId}`}
        placeholder={placeholder}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const roles = Selectors.getRoles(state) || [];
  const customRolesExist = roles.some((role) => !role.isDefault);

  const availableRoles = roles.map((role) => {
    const title = getRoleDisplayName(role, roles, I18n);
    const manageableRoles = Selectors.getManageableRoles(state);
    const disabled = !manageableRoles.some((manageableRole) => manageableRole.id == role.id);

    if (customRolesExist) {
      const group = role.isDefault ? I18n.t('users.roles.default') : I18n.t('users.roles.custom');

      return {
        title,
        value: `${role.id}`,
        disabled: disabled,
        group
      };
    } else {
      return {
        title,
        value: `${role.id}`,
        disabled: disabled
      };
    }
  });

  return {
    availableRoles
  };
};

export default connect(mapStateToProps)(RolePicker);
