import React, { Component } from 'react';
import { connect } from 'react-redux';
import startsWith from 'lodash/fp/startsWith';

import I18n from 'common/i18n';
import { invokeArgsMapValues, pickKeys } from 'common/functional_helpers';

import RoutingTab from 'common/components/TabbedPanelInterface/RoutingTab';
import * as Selectors from '../../selectors';
import * as Actions from '../actions';
import RolesLoader from '../../roles/components/RolesLoader';

class TeamsRoutingTab extends Component {
  componentDidMount() {
    const { loadTeams, loadTeamRoles } = this.props;
    loadTeamRoles();
    loadTeams();
  }

  render() {
    const props = pickProps(this.props);

    return (
      <RoutingTab key={props.to} computeIsCurrent={startsWith} {...props}>
        <RolesLoader />
        {I18n.t('users.navigation.teams')}
      </RoutingTab>
    );
  }
}

const mapDispatchToProps = {
  loadTeamRoles: Actions.loadTeamRoles,
  loadTeams: Actions.loadTeams
};
const propSelectors = {
  count: Selectors.getTeamsCount,
  loading: Selectors.getTeamsLoadingData,
  to: Selectors.getTeamsAdminPath
};
const pickProps = pickKeys(propSelectors);
const mapStateToProps = invokeArgsMapValues(propSelectors);

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(TeamsRoutingTab);
