import React, { Component } from 'react';
import { connect } from 'react-redux';
import { spring } from 'react-motion';

import I18n from 'common/i18n';
import ToastNotification from 'common/components/ToastNotification';

import * as Selectors from '../selectors';
import has from 'lodash/has';

export class Notification extends Component {
  static propTypes = {
    ...ToastNotification.propTypes
  };
  static defaultProps = {
    onDismiss: () => {}
  };
  render() {
    return <ToastNotification {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  const notificationContent = Selectors.getNotificationContent(state);
  const children = has(notificationContent, 'translationKey')
    ? I18n.t(notificationContent.translationKey, notificationContent)
    : notificationContent;

  const customTransition = {
    willEnter: () => ({ opacity: 0, right: -16 }),
    willLeave: () => ({ opacity: spring(0), right: spring(-16) }),
    style: { opacity: spring(1), right: spring(16) }
  };

  return {
    children,
    customTransition,
    showNotification: Selectors.getShowNotification(state),
    type: Selectors.getNotificationType(state)
  };
};

export { ToastType } from 'common/components/ToastNotification/Toastmaster';
export default connect(mapStateToProps)(Notification);
