// Combines the teams a member is a part of, and the teams a user owns.
// teamsOwned can come in as an object with a teamsOwned attribute or it can come in as an array.
export function generateCombinedTeamList(teamsMemberOf, teamsOwned) {
  let teamsOwnedArray;

  // sometimes teamsOwned is the user object (behavior via ResultsTable) and we need to access the teamsOwned array
  if (typeof teamsOwned === 'object' && !Array.isArray(teamsOwned)) {
    teamsOwnedArray = teamsOwned.teamsOwned || [];
  } else {
    teamsOwnedArray = teamsOwned || [];
  }

  let teamsMemberArray = teamsMemberOf || [];
  return teamsMemberArray.concat(teamsOwnedArray);
}
