import flow from 'lodash/fp/flow';
import { call, put, race, select, take, takeEvery, delay } from 'redux-saga/effects';

import { omitNilValues } from 'common/functional_helpers';
import UsersApi, { ONLY_FILTERS, STATUS_FILTERS, mapStatusFilter } from 'common/users-api';
import CoreFutureAccountsApi from 'common/core-future-accounts-api';
import { currentUserHasRight } from 'common/current_user';
import DomainRights from 'common/types/domainRights';

import * as Actions from './actions';
import * as GlobalActions from '../actions';
import * as Selectors from '../selectors';
import * as Validators from '../validators';

const disabledFilter = flow(Selectors.getUserStatusFilter, mapStatusFilter);

const selectOptions = (state) => {
  const filters = omitNilValues({
    disabled: disabledFilter(state),
    role_ids: Selectors.getUserRoleFilter(state),
    only: ONLY_FILTERS.SITE_MEMBERS,
    future: false
  });

  const query = Selectors.getUsersQuery(state);
  return {
    filters: filters,
    offset: Selectors.getUsersOffset(state),
    query,
    limit: Selectors.getUsersResultsLimit(state),
    orderBy: Selectors.getUsersOrderBy(state),
    sortDirection: Selectors.getUsersSortDirection(state)
  };
};

const selectUserFormFields = (state) => ({
  emails: Selectors.getAddUsersFormEmails(state),
  roleId: Selectors.getAddUsersFormRoleId(state)
});

export function* addUsers() {
  yield put(Actions.showAddUsersModal());

  let hasErrors;
  do {
    try {
      hasErrors = false;
      yield put(Actions.enableAddUsersModal());
      const retVal = yield race({
        submit: take(Actions.SUBMIT_ADD_USERS_MODAL),
        cancel: take(Actions.CANCEL_ADD_USERS_MODAL)
      });
      const { submit, cancel } = retVal;
      if (submit) {
        yield put(Actions.disableAddUsersModal());
        const formFields = yield select(selectUserFormFields);
        const { emails, roleId } = validateUserForm(formFields.emails, formFields.roleId);
        yield delay(250); // delay to provide user feedback
        yield call(CoreFutureAccountsApi.postFutureUsers, emails, roleId);
        const invitedUsers = yield call(CoreFutureAccountsApi.getFutureUsers); // reload users
        yield put(Actions.addUsersSuccess(invitedUsers));
        yield put(GlobalActions.showLocalizedSuccessNotification('users.notifications.add_user_success'));
      }
      if (cancel) {
        break;
      }
    } catch (error) {
      hasErrors = true;
      let errors;
      if (error instanceof CoreFutureAccountsApi.FutureAccountsCreationError) {
        errors = error.errors;
      } else if (error instanceof Validators.ValidationError) {
        errors = error.failure;
      } else {
        errors = [{ translationKey: 'users.errors.server_error_html' }];
      }
      yield put(Actions.setAddUsersFormErrors(errors));
      yield put(Actions.addUsersFailure(errors));
    }
  } while (hasErrors);
  yield put(Actions.clearAddUsersForm());
  yield put(Actions.hideAddUsersModal());
}

export function* gotoPage() {
  yield put(Actions.interactionBasedUserSearch());
}

export function* loadUsers() {
  try {
    const options = yield select(selectOptions);
    const domain = yield select(Selectors.getDomain);
    const { users, resultCount } = yield call(UsersApi.getUsers, domain, options);
    yield put(Actions.loadUsersSuccess(users, resultCount));
  } catch (error) {
    console.warn('Unable to load users, using empty list.', error);
    yield put(Actions.loadUsersFailure(error));
  }
}

export function* resetPassword({ payload: { userId } }) {
  try {
    const response = yield call(UsersApi.resetPassword, userId);
    if (response.success) {
      yield put(GlobalActions.showLocalizedSuccessNotification('users.notifications.password_reset'));
    } else {
      yield put(GlobalActions.showLocalizedErrorNotification('users.errors.unknown'));
    }
  } catch (error) {
    yield put(GlobalActions.showLocalizedErrorNotification('users.errors.unknown'));
  }
}

export function* sortColumn() {
  yield put(Actions.interactionBasedUserSearch());
}

export function* userAutocomplete({ payload: { query, callback } }) {
  if (query !== '') {
    const domain = yield select(Selectors.getDomain);
    try {
      // Only users with the `manage_users` right can see disabled users
      const status = currentUserHasRight(DomainRights.manage_users)
        ? STATUS_FILTERS.ALL
        : STATUS_FILTERS.ENABLED;
      const filters = { only: ONLY_FILTERS.SITE_MEMBERS, status };
      const searchResults = yield call(UsersApi.autocomplete, domain, query, filters);
      yield call(callback, searchResults);
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  }
}

export function* queryBasedUserSearch() {
  yield put(Actions.interactionBasedUserSearch());
}

export function* interactionBasedUserSearch() {
  try {
    const options = yield select(selectOptions);
    const domain = yield select(Selectors.getDomain);
    const { users, resultCount } = yield call(UsersApi.getUsers, domain, options);
    yield put(Actions.userSearchSuccess(users, resultCount));
  } catch (error) {
    console.warn('Unable to search for users, using empty list.', error);
    yield put(Actions.userSearchFailure(error));
  }
}

export function validateUserForm(emails, roleId) {
  // these will throw if they are provided invalid values
  Validators.isValidEmailGroup(emails);
  Validators.isValidRoleId(roleId);

  return { emails, roleId };
}

function* changeUserStatus(userId, screenName, disable) {
  try {
    const response = yield call(disable ? UsersApi.disableUser : UsersApi.enableUser, userId);
    if (response) {
      yield put(
        GlobalActions.showLocalizedSuccessNotification(
          `users.notifications.user_${disable ? 'disabled' : 'enabled'}`,
          { screenName }
        )
      );
      yield put(disable ? Actions.disableUserSuccess(userId) : Actions.enableUserSuccess(userId));
    } else {
      yield put(GlobalActions.showLocalizedErrorNotification('users.errors.unknown'));
      yield put(disable ? Actions.disableUserFailure(userId) : Actions.enableUserFailure(userId));
    }
  } catch (error) {
    yield put(GlobalActions.showLocalizedErrorNotification('users.errors.unknown'));
  }
}

export function* disableUser({ payload: { userId, screenName } }) {
  yield changeUserStatus(userId, screenName, true);
}

export function* enableUser({ payload: { userId, screenName } }) {
  yield changeUserStatus(userId, screenName, false);
}

export function* changeUserStatusFilter() {
  yield put(GlobalActions.gotoUserPage(1));
}

export function* clearUserAutocomplete() {
  yield put(Actions.interactionBasedUserSearch());
}

export default [
  takeEvery(Actions.ADD_USERS, addUsers),
  takeEvery(Actions.CHANGE_USER_STATUS_FILTER, changeUserStatusFilter),
  takeEvery(Actions.DISABLE_USER, disableUser),
  takeEvery(Actions.ENABLE_USER, enableUser),
  takeEvery(Actions.GOTO_USER_PAGE, gotoPage),
  takeEvery(Actions.LOAD_USERS, loadUsers),
  takeEvery(Actions.RESET_PASSWORD, resetPassword),
  takeEvery(Actions.SORT_USER_COLUMN, sortColumn),
  takeEvery(Actions.USER_AUTOCOMPLETE, userAutocomplete),
  takeEvery(Actions.QUERY_BASED_USER_SEARCH, queryBasedUserSearch),
  takeEvery(Actions.INTERACTION_BASED_USER_SEARCH, interactionBasedUserSearch),
  takeEvery(Actions.CLEAR_USER_AUTOCOMPLETE, clearUserAutocomplete)
];
