import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import ResultsTable from 'common/components/ResultsTable';
import DropdownButton, { DropdownItem } from 'common/components/DropdownButton';

import UserDescriptionList from './UserDescriptionList';
import Datetime from './Datetime';
import * as Selectors from '../../selectors';
import * as Actions from '../actions';
import { invokeArgsMapValues, isNotNil } from 'common/functional_helpers';

export class PlatformAdminTable extends Component {
  static propTypes = {
    loadingData: PropTypes.bool.isRequired
  };

  handlerRevoke = (id, email) => {
    const { revokeAdminAccess } = this.props;
    if (window.confirm(I18n.t('users.actions.confirm.revoke_admin_access', { email }))) {
      revokeAdminAccess(id);
    }
  };

  renderUserCell = (user) => isNotNil(user) && <UserDescriptionList {...user} />;

  renderDatetimeCell = (timestamp) => <Datetime timestamp={timestamp} />;

  renderActionsCell = (id, { targetUser: { email }, revokedAt }) => {
    return (
      <DropdownButton>
        <DropdownItem disabled={isNotNil(revokedAt)} onClick={() => this.handlerRevoke(id, email)}>
          {I18n.t('users.platform_admin_access.revoke_access')}
        </DropdownItem>
      </DropdownButton>
    );
  };

  render() {
    const { loadingData, platformAdminGrants } = this.props;
    const noResultsMessage = I18n.t('users.no_results');

    return (
      <div>
        <ResultsTable
          data={platformAdminGrants}
          rowKey="id"
          loadingData={loadingData}
          noResultsMessage={noResultsMessage}
        >
          <ResultsTable.Column
            dataIndex="targetUser"
            header={I18n.t('users.platform_admin_access.platform_admin')}
          >
            {this.renderUserCell}
          </ResultsTable.Column>
          <ResultsTable.Column
            dataIndex="grantedBy"
            header={I18n.t('users.platform_admin_access.access_granted_by')}
          >
            {this.renderUserCell}
          </ResultsTable.Column>
          <ResultsTable.Column
            dataIndex="createdAt"
            header={I18n.t('users.platform_admin_access.access_granted_at')}
          >
            {this.renderDatetimeCell}
          </ResultsTable.Column>
          <ResultsTable.Column
            dataIndex="expiresAt"
            header={I18n.t('users.platform_admin_access.access_expires_at')}
          >
            {this.renderDatetimeCell}
          </ResultsTable.Column>
          <ResultsTable.Column dataIndex="id" header={I18n.t('users.headers.actions')}>
            {this.renderActionsCell}
          </ResultsTable.Column>
        </ResultsTable>
      </div>
    );
  }
}

const mapStateToProps = invokeArgsMapValues({
  platformAdminGrants: Selectors.PlatformAdminAccess.getPlatformAdminGrants,
  loadingData: Selectors.PlatformAdminAccess.getLoadingData
});

const mapDispatchToProps = {
  revokeAdminAccess: Actions.revokeAdminAccess
};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformAdminTable);
