import { all, put, takeEvery, delay } from 'redux-saga/effects';
import * as Actions from './actions';
import teamsSagas from './teams/sagas';
import rolesSagas from './roles/sagas';
import invitedUsersSagas from './invitedUsers/sagas';
import usersSagas from './users/sagas';
import platformAdminAccessSagas from './platformAdminAccess/sagas';
import selectUsersByEmailListSagas from 'common/components/AccessManager/components/SelectUsersByEmailList/SelectUsersByEmailListSagas';

export function* showNotification() {
  yield delay(5000);
  yield put(Actions.hideNotification());
}

export default function* rootSaga() {
  yield all([
    ...invitedUsersSagas,
    ...platformAdminAccessSagas,
    ...rolesSagas,
    ...teamsSagas,
    ...usersSagas,
    ...selectUsersByEmailListSagas,
    takeEvery(Actions.SHOW_NOTIFICATION, showNotification)
  ]);
}
