import PropTypes from 'prop-types';
import React from 'react';

import I18n from 'common/i18n';

const UserDescriptionList = ({ email, displayName }) => (
  <dl className="user-description-list">
    <div className="user-description">
      <dt>{I18n.t('users.headers.name')}</dt>
      <dd>{displayName}</dd>
    </div>
    <div className="user-description">
      <dt>{I18n.t('users.headers.email')}</dt>
      <dd>{email}</dd>
    </div>
  </dl>
);

UserDescriptionList.propTypes = {
  email: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired
};

export default UserDescriptionList;
