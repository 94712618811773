import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/fp/mapValues';

import I18n from 'common/i18n';
import Dropdown from 'common/components/Dropdown';

export const AccessTimes = {
  DAYS_1: 1,
  DAYS_2: 2,
  DAYS_3: 3
};

export const accessTimeToDuration = (accessTime) => `P${accessTime}D`;

class AccessTimeChooser extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    accessTime: PropTypes.oneOf(Object.values(AccessTimes))
  };

  render() {
    const { accessTime, onChange } = this.props;

    const options = mapValues(
      (value) => ({ title: I18n.t('datetime.distance_in_words.x_days', { count: value }), value }),
      AccessTimes
    );

    return (
      <div className="access-time-chooser">
        <div className="block-label access-time-chooser-label">
          {I18n.t('users.platform_admin_access.access_time_to_grant')}:
        </div>
        <Dropdown
          onSelection={(selection) => onChange(selection.value)}
          options={options}
          size="medium"
          value={accessTime}
        />
      </div>
    );
  }
}

export default AccessTimeChooser;
