import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { TeamEligibleForDeletionResponse } from '@socrata/core-teams-api';

import I18n from 'common/i18n';
import CoreTeamsApi, { parseError } from 'common/core/teams';
import Spinner from 'common/components/Spinner';
import { Modal, ModalHeader, ModalFooter, ModalContent } from 'common/components/AccessibleModal';
import Button, { VARIANTS } from 'common/components/Button';

import { getDeleteTeamInProgress, getDeleteTeamError } from '../../../selectors.js';
import { deleteTeam } from '../../actions';
import CannotDeleteTeamReasons from './CannotDeleteTeamReasons';
import GenericError from './GenericError';

import './delete-team-modal.scss';

interface DeleteTeamModalProps {
  /** UID (4x4) of the team to delete */
  id: string;

  /** Screen name of the team to delete */
  screenName: string;

  /** Function to call to close modal */
  onClose: () => void;
}

/** You Spin Me Round (Like a Record) */
const renderLoading = (isLoading: boolean) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="delete-team-modal-spinner-container">
      <Spinner />
    </div>
  );
};

/**
 * Render an error that has come back from the API.
 * Note that this error may come from useQuery that this component does,
 * or it may come from the overall Redux state via Sagas.
 */
const renderError = (error?: { message?: string }) => {
  if (!error) {
    return null;
  }

  return <div className="alert error">{error?.message ? error.message : <GenericError />}</div>;
};

/**
 * Render the content of the modal.
 * This only renders if react-query has completed calling out to the API.
 *
 * If the team can be deleted, then a confirmation message is shown.
 * If the team cannot be deleted, then a list of reasons that the team cannot be deleted is shown.
 *
 * @param isLoadingOrError Whether or not we're waiting for a response
 *  from the react-query call to the eligible for deletion API
 * @param screenName Screen name of team being deleted
 * @param id ID (4x4) of team being deleted
 * @param canDeleteTeam Whether or not we can actually delete this team
 * @param response Response from the eligible for deletion API
 */
const renderContent = (
  isLoadingOrError: boolean,
  screenName: string,
  id: string,
  canDeleteTeam?: boolean,
  response?: TeamEligibleForDeletionResponse
) => {
  if (isLoadingOrError) {
    // either waiting on the API call (showing a spinner) or we got an error back
    return null;
  }

  if (canDeleteTeam) {
    // if we can delete the team, we just show a confirmation message
    return I18n.t('users.actions.confirm.delete_team', { name: screenName });
  }

  // if we can't delete the team, we show a list of reasons why
  return (
    <div>
      <div className="alert warning">{I18n.t('users.actions.delete_team_info.warning')}</div>
      <CannotDeleteTeamReasons id={id} response={response} />
    </div>
  );
};

/**
 * Calls out to core to determine if the given team can be deleted or not.
 * This is called using react-query when this component mounts.
 *
 * @param teamId ID (4x4) of team to check deletion eligibility for
 */
const callEligibleForDeletionApi = async (teamId: string) => {
  try {
    return await CoreTeamsApi.eligibleForDeletion({ teamId });
  } catch (errorResponse) {
    // this comes back as a raw error from fetch, so we have to parse the JSON here
    throw await parseError(errorResponse);
  }
};

/**
 * This modal is shown when trying to delete a team.
 * Currently, it is rendered by TeamsTable when you select "Delete team" from the action list.
 *
 * This modal interacts with the rest of `adminUsersV2` via Redux. When deleting a team,
 * an action is dispatched and picked up by sagas to delete the team.
 *
 * @see TeamsTable for where this is actually rendered
 * @see teams/reducers/teamsReducer (the `deletingTeam` part of the state)
 * @see teams/sagas for where deleting a team actually happens
 */
const DeleteTeamModal: FunctionComponent<DeleteTeamModalProps> = ({ id, screenName, onClose }) => {
  const dispatch = useDispatch();

  // when the confirm button is clicked, this will be set to true by the reducer
  const deleteTeamInProgress: boolean = useSelector(getDeleteTeamInProgress);

  // this will be set by sagas if there is an error actually deleting the team
  // this is NOT set by the useQuery call below, which is just checking the team's deletion eligibility
  const deleteTeamFailedError: any = useSelector(getDeleteTeamError);

  // this calls out to the "eligible for deletion" API that can be used to determine
  // if a team can be deleted or not (and, if it cannot be deleted, why)
  // this is used to determine whether or not we let the user go forward with deleting the team
  const { isLoading, isError, data, error } = useQuery(
    `team-eligible-for-deletion-${id}`,
    async () => await callEligibleForDeletionApi(id),
    { retry: false }
  );

  // the api will tell us whether or not a team can be deleted
  const canDeleteTeam = data && data.eligibleForDeletion === true;

  const modalTitle = `${I18n.t('users.actions.delete_team_info.delete_team')} '${screenName}'`;

  return (
    <Modal contentLabel={modalTitle} className="test">
      <ModalHeader onDismiss={onClose} title={modalTitle} />
      <ModalContent>
        {renderLoading(isLoading)}
        {renderError(error || deleteTeamFailedError)}
        {renderContent(isLoading || isError, screenName, id, canDeleteTeam, data)}
      </ModalContent>
      <ModalFooter>
        <Button variant={canDeleteTeam ? VARIANTS.DEFAULT : VARIANTS.PRIMARY} onClick={onClose}>
          {
            // we show a different message here based on whether or not we can delete the team
            canDeleteTeam
              ? I18n.t('users.actions.delete_team_info.cancel')
              : I18n.t('users.actions.delete_team_info.dismiss')
          }
        </Button>
        {
          // the "Delete Team" button is hidden if the team cannot be deleted
          canDeleteTeam && (
            <Button
              disabled={isLoading || isError}
              onClick={() => dispatch(deleteTeam(id))}
              variant={VARIANTS.ERROR}
              busy={deleteTeamInProgress}
            >
              {I18n.t('users.actions.delete_team_info.delete_team')}
            </Button>
          )
        }
      </ModalFooter>
    </Modal>
  );
};

const queryClient = new QueryClient();

export default (props: DeleteTeamModalProps) => (
  <QueryClientProvider client={queryClient}>
    <DeleteTeamModal {...props} />
  </QueryClientProvider>
);
