import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import sagas from './sagas';
import * as PlatformAdminAccessActions from './platformAdminAccess/actions';
import * as TeamActions from './teams/actions';

const createAdminUsersStore = () => {
  const serverConfig = window.serverConfig;

  const sagaMiddleware = createSagaMiddleware();

  const devToolsConfig = {
    actionsDenylist: [
      TeamActions.UPDATE_TEAM_FORM,
      TeamActions.USER_SEARCH_QUERY_CHANGED,
      PlatformAdminAccessActions.CHANGE_PLATFORM_ADMIN_EMAIL
    ],
    name: 'Users & Teams Admin'
  };
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsConfig)) ||
    compose;

  const preloadedState = {
    config: {
      ...serverConfig
    }
  };

  const store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(sagas);

  return store;
};

export default createAdminUsersStore;
