import isEmpty from 'lodash/fp/isEmpty';
import isFinite from 'lodash/fp/isFinite';
import negate from 'lodash/fp/negate';

import { CORE_EMAIL_REGEX } from 'common/js_utils/accounts';

const isPresent = negate(isEmpty);

export class ValidationError extends Error {
  constructor(failure) {
    super(failure);
    this.name = 'ValidationError';
    this.failure = failure;
    this.message = failure.toString();
    this.stack = new Error().stack;
  }
}

export const isValidEmailGroup = (emails) => {
  const errors = [];

  if (!isPresent(emails)) {
    throw new ValidationError([{ translationKey: 'users.errors.no_emails_provided' }]);
  }

  emails.split(',').forEach((email) => {
    if (isEmpty(email) || !RegExp(CORE_EMAIL_REGEX).test(email.trim())) {
      errors.push({ translationKey: 'users.errors.invalid_email', value: email.trim() });
    }
  });

  if (errors.length !== 0) {
    throw new ValidationError(errors);
  }
};

export const isValidRoleId = (id) => {
  const roleId = parseInt(id, 10);
  if (!isFinite(roleId) || roleId <= 0) {
    throw new ValidationError([{ translationKey: 'users.errors.invalid_role_selected' }]);
  }
};
