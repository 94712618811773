import PropTypes from 'prop-types';
import React from 'react';
import isNil from 'lodash/isNil';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import DateFromNow from 'common/components/DateFromNow';
import DropdownButton, { DropdownItem } from 'common/components/DropdownButton';
import ResultsTable from 'common/components/ResultsTable';

import { getRoleDisplayName, roleIdsEqual } from '../../utils';
import { InvitedUserPropType } from '../propTypes';
import * as Actions from '../actions';
import * as Selectors from '../../selectors';

export class InvitedUsersTable extends React.Component {
  static propTypes = {
    invitedUsers: PropTypes.arrayOf(InvitedUserPropType).isRequired,
    loadingData: PropTypes.bool.isRequired,
    manageableRoles: PropTypes.array.isRequired
  };

  renderInvitedCell = (createdAt) => <DateFromNow timestamp={createdAt} />;

  handleRemoveInvitedUser = (id, email) => {
    const { removeInvitedUser } = this.props;
    if (window.confirm(I18n.t('users.actions.confirm.remove_invited_user', { email }))) {
      removeInvitedUser(id);
    }
  };

  renderActionsCell = (id, { email, pendingRoleId }) => {
    const { resendInvitedUserEmail, manageableRoles } = this.props;
    if (manageableRoles.some((role) => role.id == pendingRoleId)) {
      return (
        <DropdownButton>
          <DropdownItem onClick={() => this.handleRemoveInvitedUser(id, email)}>
            {I18n.t('users.actions.remove_invited_user')}
          </DropdownItem>
          <DropdownItem onClick={() => resendInvitedUserEmail(email)}>
            {I18n.t('users.actions.resend_invited_user_email')}
          </DropdownItem>
        </DropdownButton>
      );
    } else {
      return null;
    }
  };

  render() {
    const { invitedUsers, loadingData } = this.props;
    const noResultsMessage = I18n.t('users.no_results');
    return (
      <ResultsTable
        data={invitedUsers}
        rowKey="id"
        loadingData={loadingData}
        noResultsMessage={noResultsMessage}
      >
        <ResultsTable.Column dataIndex="email" header={I18n.t('users.headers.email')} />
        <ResultsTable.Column dataIndex="role" header={I18n.t('users.headers.role')} />
        <ResultsTable.Column dataIndex="createdAt" header={I18n.t('users.headers.invited')}>
          {this.renderInvitedCell}
        </ResultsTable.Column>
        <ResultsTable.Column dataIndex="id" header={I18n.t('users.headers.actions')}>
          {this.renderActionsCell}
        </ResultsTable.Column>
      </ResultsTable>
    );
  }
}

const mapStateToProps = (state) => {
  const roles = Selectors.getRoles(state);
  const findRole = (roleId) => roles.find(({ id }) => roleIdsEqual(id, roleId));
  const loadingData = Selectors.getInvitedUsersLoading(state) && Selectors.getRolesLoading(state);
  const transformUserToIncludeRole = (invitedUser) => ({
    ...invitedUser,
    role: getRoleDisplayName(findRole(invitedUser.pendingRoleId), roles, I18n)
  });
  const getInvitedUsers = () => {
    const userRoleFilter = Selectors.getUserRoleFilter(state);
    const allInvitedUsers = Selectors.getInvitedUsers(state);
    const filteredInvitedUsers = isNil(userRoleFilter)
      ? allInvitedUsers
      : allInvitedUsers.filter(({ pendingRoleId }) => roleIdsEqual(pendingRoleId, userRoleFilter));
    return filteredInvitedUsers.map(transformUserToIncludeRole);
  };
  const invitedUsers = loadingData || roles.length === 0 ? [] : getInvitedUsers();
  return {
    invitedUsers,
    loadingData,
    manageableRoles: Selectors.getManageableRoles(state)
  };
};

const mapDispatchToProps = {
  removeInvitedUser: Actions.removeInvitedUser,
  resendInvitedUserEmail: Actions.resendInvitedUserEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitedUsersTable);
