import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import PagerGroup from 'common/components/PagerGroup';

import * as Actions from '../actions';
import * as Selectors from '../../selectors';

const mapStateToProps = (state) => {
  const searchResultCount = Selectors.getUserSearchResultCount(state);

  return {
    currentPage: Selectors.getUsersCurrentPage(state),
    resultCount: isUndefined(searchResultCount) ? Selectors.getUsersResultCount(state) : searchResultCount,
    resultsPerPage: Selectors.getUsersResultsLimit(state)
  };
};

const mapDispatchToProps = {
  onChangePage: Actions.gotoUserPage
};
export default connect(mapStateToProps, mapDispatchToProps)(PagerGroup);
