import React from 'react';
import InvitedUsersTable from './InvitedUsersTable';
import AddUserButton from '../../users/components/AddUserButton';
import UserRoleFilter from '../../users/components/UserRoleFilter';

const InvitedUsers = () => (
  <div>
    <div className="user-search-bar search-bar">
      <UserRoleFilter />
      <AddUserButton />
    </div>
    <InvitedUsersTable />
  </div>
);

export default InvitedUsers;
