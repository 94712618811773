import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import DropdownButton, { DropdownItem } from 'common/components/DropdownButton';

import * as Actions from '../actions';
import * as RolesActions from '../../roles/actions';
import * as Selectors from '../../selectors';

export class UsersDropdownButton extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired, // This represents being disabled on the Site
    disabledOnOrganization: PropTypes.bool.isRequired, // In contrast to the above, this should be self-evident
    roleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    screenName: PropTypes.string,
    onDisableUser: PropTypes.func.isRequired,
    onEnableUser: PropTypes.func.isRequired,
    onRemoveUserRole: PropTypes.func.isRequired,
    onResetPassword: PropTypes.func.isRequired
  };

  handleRemoveUserRole = () => {
    const { userId, roleId, screenName, onRemoveUserRole } = this.props;

    if (window.confirm(I18n.t('users.actions.confirm.remove_user', { screenName }))) {
      onRemoveUserRole(userId, roleId);
    }
  };

  handleDisableUser = () => {
    const { userId, screenName, onDisableUser } = this.props;

    if (window.confirm(I18n.t('users.actions.confirm.disable_user', { screenName }))) {
      onDisableUser(userId, screenName);
    }
  };

  handleEnableUser = () => {
    const { userId, screenName, onEnableUser } = this.props;

    if (window.confirm(I18n.t('users.actions.confirm.enable_user', { screenName }))) {
      onEnableUser(userId, screenName);
    }
  };

  renderEnableDisable = () => {
    const { userId, disabled, disabledOnOrganization, screenName, currentUserId } = this.props;
    const isCurrentUser = userId === currentUserId;
    const title = disabledOnOrganization ? I18n.t('users.actions.disabled_on_organization') : null;
    let handler;
    let message;

    if (disabledOnOrganization) {
      return null;
    }

    if (disabled) {
      handler = () => this.handleEnableUser(userId, screenName);
      message = I18n.t('users.actions.enable');
    } else {
      handler = () => this.handleDisableUser(userId, screenName);
      message = I18n.t('users.actions.disable');
    }

    return (
      <DropdownItem disabled={isCurrentUser || disabledOnOrganization} onClick={handler} title={title}>
        {message}
      </DropdownItem>
    );
  };

  renderRemoveRole = () => {
    const { userId, roleId, screenName, currentUserId } = this.props;
    const isCurrentUser = userId === currentUserId;

    const handler = () => this.handleRemoveUserRole(userId, roleId, screenName);
    const message = I18n.t('users.actions.remove_role');

    return (
      <DropdownItem disabled={isCurrentUser} onClick={handler}>
        {message}
      </DropdownItem>
    );
  };

  handleResetPassword = () => {
    const { userId, screenName, onResetPassword } = this.props;

    if (window.confirm(I18n.t('users.actions.confirm.reset_password', { screenName }))) {
      onResetPassword(userId);
    }
  };

  renderResetPassword = () => {
    const { userId, disabled, screenName } = this.props;

    if (disabled) {
      return null;
    }

    return (
      <DropdownItem
        onClick={() => this.handleResetPassword(userId, screenName)}
        childProps={{ 'data-test-selector': 'resetPassword' }}
      >
        {I18n.t('users.actions.reset_password')}
      </DropdownItem>
    );
  };

  render() {
    const { disabledOnOrganization, roleId } = this.props;

    return (
      <DropdownButton isDisabled={roleId === 'none' || disabledOnOrganization}>
        {this.renderEnableDisable()}
        {this.renderResetPassword()}
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserId: Selectors.getCurrentUserId(state)
});

const mapDispatchToProps = {
  onDisableUser: Actions.disableUser,
  onEnableUser: Actions.enableUser,
  onRemoveUserRole: RolesActions.removeUserRole,
  onResetPassword: Actions.resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersDropdownButton);
