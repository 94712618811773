import React, { Component } from 'react';
import I18n from 'common/i18n';

export default class TeamMemberCSVExportButton extends Component {
  render() {
    const generatedFile = 'data:text/csv;charset=utf-8,' + encodeURI(this.createMembersFile());

    return (
      <a href={generatedFile} download={this.getFileName()} className="btn btn-simple export-csv-btn">
        {I18n.t('users.export_as_csv')}
      </a>
    );
  }

  getFileName() {
    if (this.props.teamName != undefined) {
      return this.props.teamName + '.csv';
    } else {
      return '';
    }
  }

  createMembersFile() {
    // start file with User info headers
    let fileContents = 'Display Name,Email,Team Role\n';
    if (this.props.data != undefined) {
      this.props.data.forEach((row) => {
        let email = '';
        let userName = '';
        let role = '';

        if (row.email != undefined) email = row.email;
        if (row.displayName != undefined) userName = row.displayName;
        if (row.teamRole != undefined) role = row.teamRole;

        let fileRow = userName.concat(',', email).concat(',', role) + '\n';
        fileContents += fileRow;
      });
    }

    return fileContents;
  }
}
