import { Team, TeamRole, TeamMember } from '@socrata/core-teams-api';
import { isArray } from 'lodash';
import { AlertListAlertProps } from 'common/components/AlertList/AlertListTypes';
import { UsersCatalogSearchResults } from 'common/types/users/catalogUsers';

/** Load teams
------------------------------------------------------------------------------- **/
export const LOAD_TEAMS = 'LOAD_TEAMS';
export const loadTeams = () => ({ type: LOAD_TEAMS });

export const LOAD_TEAMS_SUCCESS = 'LOAD_TEAMS_SUCCESS';
export const loadTeamsSuccess = (teams: Team[], resultCount: number) => ({
  type: LOAD_TEAMS_SUCCESS,
  payload: { teams, resultCount }
});

export const LOAD_TEAMS_FAILURE = 'LOAD_TEAMS_FAILURE';
export const loadTeamsFailure = (error: any) => ({
  type: LOAD_TEAMS_FAILURE,
  error: true,
  payload: { error }
});

/** Search Teams
------------------------------------------------------------------------------- **/
export const TEAMS_SEARCH = 'TEAMS_SEARCH';
export const teamsSearch = (query?: string) => ({ type: TEAMS_SEARCH, payload: { query } });

export const TEAMS_AUTOCOMPLETE = 'TEAMS_AUTOCOMPLETE';
export const teamsAutocomplete = (query: string, callback: (searchResults: any) => void) => ({
  type: TEAMS_AUTOCOMPLETE,
  payload: { query, callback }
});

export const TEAMS_SEARCH_SUCCESS = 'TEAMS_SEARCH_SUCCESS';
export const teamsSearchSuccess = (teams: Team[], resultCount: number) => ({
  type: TEAMS_SEARCH_SUCCESS,
  payload: { teams, resultCount }
});

export const TEAMS_SEARCH_FAILURE = 'TEAMS_SEARCH_FAILURE';
export const teamsSearchFailure = (error: any) => ({
  type: TEAMS_SEARCH_FAILURE,
  error: true,
  payload: { error }
});

/** Team Viewer
------------------------------------------------------------------------------- **/
export const TEAM_VIEW_NAVIGATION = 'TEAM_VIEW_NAVIGATION';
export const teamViewNavigation = (id: string) => ({ type: TEAM_VIEW_NAVIGATION, payload: { id } });

export const GOTO_TEAM_PAGE = 'GOTO_TEAM_PAGE';
export const gotoTeamPage = (page: number) => ({ type: GOTO_TEAM_PAGE, payload: { page } });

export const SORT_TEAM_COLUMN = 'SORT_TEAM_COLUMN';
export const sortTeamColumn = (columnKey: string) => ({ type: SORT_TEAM_COLUMN, payload: { columnKey } });

// TODO: Debounce/throttle?
export const UPDATE_TEAM_FORM = 'UPDATE_TEAM_FORM';
export const updateTeamForm = (screenName: string, description: string) => ({
  type: UPDATE_TEAM_FORM,
  payload: { screenName, description }
});

export const LOAD_TEAM = 'LOAD_TEAM';
export const loadTeam = (id: string) => ({ type: LOAD_TEAM, payload: { id } });

export const LOAD_TEAM_SUCCESS = 'LOAD_TEAM_SUCCESS';
export const loadTeamSuccess = (team: Team) => ({ type: LOAD_TEAM_SUCCESS, payload: { team } });

export const LOAD_TEAM_FAILURE = 'LOAD_TEAM_FAILURE';
export const loadTeamFailure = (error: any) => ({ type: LOAD_TEAM_FAILURE, error: true, payload: { error } });

export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER';
export const removeTeamMember = (teamId: string, userId: string) => ({
  type: REMOVE_TEAM_MEMBER,
  payload: { teamId, userId }
});

export const REMOVE_TEAM_MEMBER_SUCCESS = 'REMOVE_TEAM_MEMBER_SUCCESS';
export const removeTeamMemberSuccess = (teamId: string, userId: string) => ({
  type: REMOVE_TEAM_MEMBER_SUCCESS,
  payload: { teamId, userId }
});

export const CHANGE_MEMBER_ROLE = 'CHANGE_MEMBER_ROLE';
export const changeMemberRole = (teamId: string, userId: string, roleId: TeamRole) => ({
  type: CHANGE_MEMBER_ROLE,
  payload: { teamId, userId, roleId }
});

export const CHANGE_MEMBER_ROLE_SUCCESS = 'CHANGE_MEMBER_ROLE_SUCCESS';
export const changeMemberRoleSuccess = (teamId: string, userId: string, roleId: TeamRole) => ({
  type: CHANGE_MEMBER_ROLE_SUCCESS,
  payload: { teamId, userId, roleId }
});

/** Load Team Roles
------------------------------------------------------------------------------- **/
export const LOAD_TEAM_ROLES = 'LOAD_TEAM_ROLES';
export const loadTeamRoles = () => ({ type: LOAD_TEAM_ROLES });

export const LOAD_TEAM_ROLES_SUCCESS = 'LOAD_TEAM_ROLES_SUCCESS';
export const loadTeamRolesSuccess = (teamRoles: TeamRole[]) => ({
  type: LOAD_TEAM_ROLES_SUCCESS,
  payload: { teamRoles }
});

export const LOAD_TEAM_ROLES_FAILURE = 'LOAD_TEAM_ROLES_FAILURE';
export const loadTeamRolesFailure = (error: any) => ({
  type: LOAD_TEAM_ROLES_FAILURE,
  error: true,
  payload: { error }
});

/** Delete Team
------------------------------------------------------------------------------- **/
export const DELETE_TEAM = 'DELETE_TEAM';
export const deleteTeam = (id: string) => ({ type: DELETE_TEAM, payload: { id } });

export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const deleteTeamSuccess = (id: string) => ({ type: DELETE_TEAM_SUCCESS, payload: { id } });

export const DELETE_TEAM_FAILURE = 'DELETE_TEAM_FAILURE';
export const deleteTeamFailure = (error: any) => ({
  type: DELETE_TEAM_FAILURE,
  error: true,
  payload: { error }
});

export const SHOW_DELETE_TEAM_MODAL = 'SHOW_DELETE_TEAM_MODAL';
export const showDeleteTeamModal = (id: string, screenName: string) => ({
  type: SHOW_DELETE_TEAM_MODAL,
  payload: { id, screenName }
});

export const HIDE_DELETE_TEAM_MODAL = 'HIDE_DELETE_TEAM_MODAL';
export const hideDeleteTeamModal = () => ({ type: HIDE_DELETE_TEAM_MODAL });

/** Edit Team Modal Actions
------------------------------------------------------------------------------- **/
export const SHOW_EDIT_TEAM_MODAL = 'SHOW_EDIT_TEAM_MODAL';
export const showEditTeamModal = () => ({ type: SHOW_EDIT_TEAM_MODAL });

export const HIDE_EDIT_TEAM_MODAL = 'HIDE_EDIT_TEAM_MODAL';
export const hideEditTeamModal = () => ({ type: HIDE_EDIT_TEAM_MODAL });

export const ADD_TEAM_MEMBERS = 'ADD_TEAM_MEMBERS';
export const addTeamMembers = (teamId: string) => ({ type: ADD_TEAM_MEMBERS, payload: { teamId } });

export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS';
export const addTeamMemberSuccess = (teamId: string, newMember: TeamMember) => ({
  type: ADD_TEAM_MEMBER_SUCCESS,
  payload: { teamId, newMember }
});

export const ADD_TEAM_MEMBER_FAILURE = 'ADD_TEAM_MEMBER_FAILURE';
export const addTeamMemberFailure = (error: any) => ({
  type: ADD_TEAM_MEMBER_FAILURE,
  error: true,
  payload: { error }
});

/** Add Team Member Modal Actions
------------------------------------------------------------------------------- **/
export const SHOW_ADD_TEAM_MEMBERS_MODAL = 'SHOW_ADD_TEAM_MEMBERS_MODAL';
export const showAddTeamMembersModal = () => ({ type: SHOW_ADD_TEAM_MEMBERS_MODAL });

export const HIDE_ADD_TEAM_MEMBERS_MODAL = 'HIDE_ADD_TEAM_MEMBERS_MODAL';
export const hideAddTeamMembersModal = () => ({ type: HIDE_ADD_TEAM_MEMBERS_MODAL });

export const DISABLE_ADD_TEAM_MEMBERS_MODAL = 'DISABLE_ADD_TEAM_MEMBERS_MODAL';
export const disableAddTeamMembersModal = () => ({ type: DISABLE_ADD_TEAM_MEMBERS_MODAL });

export const ENABLE_ADD_TEAM_MEMBERS_MODAL = 'ENABLE_ADD_TEAM_MEMBERS_MODAL';
export const enableAddTeamMembersModal = () => ({ type: ENABLE_ADD_TEAM_MEMBERS_MODAL });

// Called when one or more user records is added to the selected users list
// Receives one or more search result, but adds the user object portion of each to the state
export const ADD_TO_SELECTED_USERS = 'ADD_TO_SELECTED_USERS';
export const addToSelectedUsers = (users?: UsersCatalogSearchResults[] | UsersCatalogSearchResults) => ({
  type: ADD_TO_SELECTED_USERS,
  payload: { users: isArray(users) ? users : [users?.user] }
});

// Called when removing a user record from the selected user list
export const REMOVE_FROM_SELECTED_USERS = 'REMOVE_FROM_SELECTED_USERS';
export const removeFromSelectedUsers = (user: any) => ({
  type: REMOVE_FROM_SELECTED_USERS,
  payload: { user }
});

// Called to clear the selected user list
export const CLEAR_SELECTED_USERS = 'CLEAR_SELECTED_USERS';
export const clearSelectedUsers = () => ({ type: CLEAR_SELECTED_USERS });

// Called when an email string is added to the list of alerts
export const ADD_TO_SELECT_USER_ALERTS = 'ADD_TO_SELECT_USER_ALERTS';
export const addToSelectUserAlerts = (alerts: AlertListAlertProps[] | AlertListAlertProps) => ({
  type: ADD_TO_SELECT_USER_ALERTS,
  payload: { alerts: isArray(alerts) ? alerts : [alerts] }
});

// Called when removing an alert from the list of alerts
export const REMOVE_FROM_SELECT_USER_ALERTS = 'REMOVE_FROM_SELECT_USER_ALERTS';
export const removeFromSelectUserAlerts = (alerts: AlertListAlertProps[] | AlertListAlertProps) => ({
  type: REMOVE_FROM_SELECT_USER_ALERTS,
  payload: { alerts: isArray(alerts) ? alerts : [alerts] }
});

// Called to clear the list of alerts
export const CLEAR_SELECT_USER_ALERTS = 'CLEAR_SELECT_USER_ALERTS';
export const clearSelectUserAlerts = () => ({ type: CLEAR_SELECT_USER_ALERTS });

export const CANCEL_ADD_TEAM_MEMBERS_MODAL = 'CANCEL_ADD_TEAM_MEMBERS_MODAL';
export const cancelAddTeamMembersModal = () => ({ type: CANCEL_ADD_TEAM_MEMBERS_MODAL });

export const SUBMIT_ADD_TEAM_MEMBERS_MODAL = 'SUBMIT_ADD_TEAM_MEMBERS_MODAL';
export const submitAddTeamMembersModal = () => ({ type: SUBMIT_ADD_TEAM_MEMBERS_MODAL });

/** User Search Actions
------------------------------------------------------------------------------- **/
// Called when the user is typing in to the search box
export const USER_SEARCH_QUERY_CHANGED = 'USER_SEARCH_QUERY_CHANGED';
export const userSearchQueryChanged = (currentQuery: string, filters: object) => ({
  type: USER_SEARCH_QUERY_CHANGED,
  payload: { currentQuery, filters }
});

// Called to clear the current query from the search box
export const USER_SEARCH_CLEAR_QUERY = 'USER_SEARCH_CLEAR_QUERY';
export const userSearchClearQuery = () => ({
  type: USER_SEARCH_CLEAR_QUERY,
  payload: { currentQuery: '' }
});

export const USER_SEARCH_RESULTS = 'USER_SEARCH_RESULTS';
export const userSearchResults = (results: any[]) => ({
  type: USER_SEARCH_RESULTS,
  payload: { results }
});
