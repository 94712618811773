import PropTypes from 'prop-types';

export const MemberPropType = PropTypes.shape({
  displayName: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  teamRole: PropTypes.string
});

export const TeamPropType = PropTypes.shape({
  createdAt: PropTypes.number,
  description: PropTypes.string,
  id: PropTypes.string,
  members: PropTypes.arrayOf(MemberPropType),
  screenName: PropTypes.string
});
