import React from 'react';
import PlatformAdminTable from './PlatformAdminTable';
import PlatformAdminAccessToolbar from './PlatformAdminAccessToolbar';

const PlatformAdminAccess = () => {
  return (
    <div>
      <PlatformAdminAccessToolbar />
      <PlatformAdminTable />
    </div>
  );
};

export default PlatformAdminAccess;
