import add from 'lodash/fp/add';
import compact from 'lodash/fp/compact';
import curryRight from 'lodash/fp/curryRight';
import eq from 'lodash/fp/eq';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import negate from 'lodash/fp/negate';
import set from 'lodash/fp/set';
import uniq from 'lodash/fp/uniq';
import uniqBy from 'lodash/fp/uniqBy';
import unionBy from 'lodash/fp/unionBy';
import * as Actions from '../actions';
import { SORT_DIRECTION, SORT_KEYS } from 'common/teams-api';
import { makeZeroBasedPageFromPager } from '../../utils';
import { mapUsers } from '../../users/reducers';

/** Initial State
------------------------------------------------------------------------------- **/
export const initialState = {
  addMemberErrors: [],
  addMemberSuccesses: [],
  currentTeamId: null,
  offset: 0,
  orderBy: SORT_KEYS.SCREEN_NAME,
  resultCount: 0,
  searchQuery: null,
  searchResultCount: undefined,
  sortDirection: SORT_DIRECTION.ASC,
  teamForm: {
    id: null,
    screenName: '',
    description: '',
    errors: []
  },
  teams: [],
  teamRoles: [],
  userSearch: {
    selectedUsers: [],
    selectUserAlerts: [],
    currentQuery: '',
    filters: {},
    results: null
  },
  zeroBasedPage: 0,
  deletingTeam: {
    // if the delete team modal is open,
    // these will be set to the id/screen name of the team we're trying to delete
    deletingTeamId: undefined,
    deletingTeamScreenName: undefined,

    // this is set to true while we wait for a response from the delete team API
    inProgress: false,

    // this will get set when we get an error back from the API
    error: undefined
  }
};

/** General Helpers
------------------------------------------------------------------------------- **/
export const getUserId = get('id');
export const getUserScreenName = get('screen_name'); //  this field is part of the 'user' object given back from core
export const getUserDisplayName = get('displayName'); // this field is part of the 'user' object in our state
const notIncludes = negate(includes);

/** Teams List
------------------------------------------------------------------------------- **/
const handleLoadTeamsSuccess = (state, { teams, resultCount }) => ({
  ...state,
  resultCount,
  teams: unionBy('id', state.teams, teams)
});

const handleTeamViewNavigation = (state, { id }) => ({
  ...state,
  currentTeamId: id
});

/** Manage Teams
------------------------------------------------------------------------------- **/
const handleUpdateTeamForm = (state, { screenName, description }) => {
  return set('teamForm', { id: getTeamFormId(state), screenName, description, errors: [] }, state);
};

const handleLoadTeamSuccess = (state, { team }) => {
  const teamMembersListWithDisabledFlag = { ...team, members: getMembers(team) };
  return isEmpty(state.teams)
    ? { ...state, teams: [teamMembersListWithDisabledFlag] }
    : set(
        'teams',
        getTeamsList(state).map((t) =>
          t.id === teamMembersListWithDisabledFlag.id ? teamMembersListWithDisabledFlag : t
        ),
        state
      );
};

const handleLoadTeamRolesSuccess = (state, { teamRoles }) => ({ ...state, teamRoles });

export const mapResultToMember = (result, teamRole) => ({
  ...result,
  displayName: getUserScreenName(result),
  teamRole
});

const handleShowDeleteTeamModal = (state, { id, screenName }) => ({
  ...state,
  deletingTeam: {
    deletingTeamId: id,
    deletingTeamScreenName: screenName
  }
});

const handleHideDeleteTeamModal = (state) => ({
  ...state,
  deletingTeam: {
    deletingTeamId: undefined,
    deletingTeamScreenName: undefined
  }
});

// this happens when the confirm button on the delete team modal is clicked
// the actual deletion happens in the corresponding sagas
const handleDeleteTeamStart = (state) => ({
  ...state,
  deletingTeam: {
    inProgress: true,
    error: undefined
  }
});

// triggered by the sagas when deleting the team works successfully
const handleDeleteTeamSuccess = (state, { id }) => ({
  ...state,
  teams: getTeamsList(state).filter((t) => t.id !== id),
  deletingTeam: {
    inProgress: false,
    error: undefined
  }
});

// triggered by the sagas when deleting the team fails
const handleDeleteTeamFailure = (state, { error }) => ({
  ...state,
  deletingTeam: {
    inProgress: false,
    error
  }
});

const handleColumnSort = (state, { columnKey }) => {
  const orderBy = getTeamsOrderBy(state);
  const sortDirection = getTeamsSortDirection(state);
  if (orderBy !== columnKey) {
    return { ...state, orderBy: columnKey, sortDirection: initialState.sortDirection };
  } else {
    return {
      ...state,
      sortDirection: SORT_DIRECTION[Object.values(SORT_DIRECTION).filter((val) => val !== sortDirection)[0]]
    };
  }
};

const handleGotoPage = (state, { page }) => ({
  ...state,
  zeroBasedPage: makeZeroBasedPageFromPager(page)
});

const handleTeamsSearch = (state, { query }) => ({
  ...state,
  loadingData: true,
  searchQuery: query
});

const handleTeamsSearchSuccess = (state, { teams, resultCount }) => ({
  ...state,
  loadingData: false,
  searchResultCount: resultCount,
  teams
});

/** Manage Team Members
------------------------------------------------------------------------------- **/

/** Team Member Roles
--------------------------------------------------------------------- **/
const handleChangeMemberRoleSuccess = (state, { teamId, userId, roleId }) => ({
  ...state,
  teams: state.teams.map((team) =>
    team.id === teamId
      ? {
          ...team,
          members: getMembers(team).map((member) =>
            member.id === userId ? { ...member, teamRole: roleId } : member
          )
        }
      : team
  )
});

/** Remove Team Members
--------------------------------------------------------------------- **/
const handleRemoveTeamMemberSuccess = (state, { teamId, userId }) => ({
  ...state,
  teams: state.teams.map((team) =>
    team.id === teamId
      ? {
          ...team,
          members: filter((m) => m.id !== userId, getMembers(team))
        }
      : team
  )
});

/** Add Team Members
--------------------------------------------------------------------- **/
const handleCancelAddTeamMembersModal = (state) => ({
  ...state,
  addMemberErrors: [],
  addMemberSuccesses: [],
  userSearch: {
    selectedUsers: [],
    selectUserAlerts: [],
    currentQuery: '',
    filters: {},
    results: null
  }
});

/** Successes and Failures
----------------------------------------------------------- **/
const handleAddTeamMemberSuccess = (state, { teamId, newMember }) => ({
  ...state,
  addMemberSuccesses: [...getAddMemberSuccesses(state), newMember],
  teams: state.teams.map((team) =>
    team.id === teamId
      ? {
          ...team,
          members: [...getMembers(team), newMember]
        }
      : team
  ),
  userSearch: {
    selectedUsers: filter((u) => u.id !== newMember.id, getSelectedUsers(state)),
    selectUserAlerts: [],
    currentQuery: '',
    filters: {},
    results: null
  }
});

const handleAddTeamMemberFailure = (state, { error }) => ({
  ...state,
  addMemberErrors: [...getAddMemberErrors(state), error]
});

/** Alerts
------------------------------------------------- **/
const getAlertId = get('alertId');

const handleAddToSelectUserAlerts = (state, { alerts }) => {
  const response = {
    ...state,
    userSearch: {
      ...state.userSearch,
      selectUserAlerts: flow(uniqBy(getAlertId), compact)(getSelectUserAlerts(state).concat(alerts))
    }
  };

  return response;
};

const handleRemoveFromSelectUserAlerts = (state, { alerts }) => {
  const removeAlertIds = alerts.map((alert) => getAlertId(alert));
  const response = {
    ...state,
    userSearch: {
      ...state.userSearch,
      selectUserAlerts: filter(
        // Keep the alert if it isn't in our list to remove
        (a) => removeAlertIds.indexOf(getAlertId(a)) === -1,
        getSelectUserAlerts(state)
      )
    }
  };

  return response;
};

const handleClearSelectUserAlerts = (state) => {
  const response = {
    ...state,
    userSearch: {
      ...state.userSearch,
      selectUserAlerts: []
    }
  };

  return response;
};

/** User Search
----------------------------------------------------------- **/

/** Selected Users
------------------------------------------------- **/
const handleAddToSelectedUsers = (state, { users }) => {
  const response = {
    ...state,
    addMemberErrors: [],
    addMemberSuccesses: [],
    userSearch: {
      ...state.userSearch,
      currentQuery: '',
      results: null,
      emailListQuery: '',
      selectedUsers: !isEmpty(users)
        ? flow(uniqBy(getUserId), compact)(getSelectedUsers(state).concat(users))
        : state.userSearch.selectedUsers || []
    }
  };

  return response;
};

const handleRemoveFromSelectedUsers = (state, { user }) => {
  const response = {
    ...state,
    addMemberErrors: [],
    addMemberSuccesses: [],
    userSearch: {
      ...state.userSearch,
      selectedUsers: filter((u) => getUserId(u) !== getUserId(user), getSelectedUsers(state))
    }
  };

  return response;
};

const handleClearSelectedUsers = (state) => {
  const response = {
    ...state,
    addMemberErrors: [],
    addMemberSuccesses: [],
    userSearch: {
      ...state.userSearch,
      currentQuery: '',
      results: null,
      emailListQuery: '',
      emailList: [],
      selectedUsers: [],
      selectUserAlerts: []
    }
  };

  return response;
};

/** Autocomplete
------------------------------------------------- **/
const handleUserSearchQueryChanged = (state, { currentQuery, filters }) => ({
  ...state,
  addMemberErrors: [],
  addMemberSuccesses: [],
  userSearch: {
    ...state.userSearch,
    currentQuery,
    filters
  }
});

const handleUserSearchClearQuery = (state, { currentQuery }) => ({
  ...state,
  userSearch: {
    ...state.userSearch,
    currentQuery: currentQuery === '' ? currentQuery : ''
  }
});

/** Results
------------------------------------------------- **/

/** Helpers
--------------------------------------- **/
const filterSelectedOrCurrentMembersFromResults = (state, results) => {
  const existingMemberIds = getMemberIds(getCurrentTeam(state));
  const selectedUserIds = getSelectedUserIds(state);
  const filterIds = selectedUserIds.concat(existingMemberIds);

  return results.filter((result) => {
    const id = result.id || result.user?.id || result.team?.id;
    return filterIds.indexOf(id) === -1;
  });
};

/** Handlers
--------------------------------------- **/
const handleUserSearchResults = (state, { results }) => ({
  ...state,
  addMemberErrors: [],
  addMemberSuccesses: [],
  userSearch: {
    ...state.userSearch,
    results: filterSelectedOrCurrentMembersFromResults(state, results),
    selectUserAlerts: []
  }
});

/** Submission
--------------------------------------------------------------------- **/
const handleSubmitAddTeamMembersModal = (state) => ({
  ...state,
  addMemberErrors: [],
  addMemberSuccesses: [],
  userSearch: {
    ...state.userSearch,
    selectUserAlerts: []
  }
});

/** Reducer
------------------------------------------------------------------------------- **/
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    /** Teams List
    ------------------------------------------------------- **/
    case Actions.LOAD_TEAMS_SUCCESS:
      return handleLoadTeamsSuccess(state, payload);

    case Actions.TEAM_VIEW_NAVIGATION:
      return handleTeamViewNavigation(state, payload);

    /** Manage Teams
    ------------------------------------------------------- **/
    case Actions.UPDATE_TEAM_FORM:
      return handleUpdateTeamForm(state, payload);

    case Actions.LOAD_TEAM_SUCCESS:
      return handleLoadTeamSuccess(state, payload);

    case Actions.LOAD_TEAM_ROLES_SUCCESS:
      return handleLoadTeamRolesSuccess(state, payload);

    case Actions.SHOW_DELETE_TEAM_MODAL:
      return handleShowDeleteTeamModal(state, payload);

    case Actions.HIDE_DELETE_TEAM_MODAL:
      return handleHideDeleteTeamModal(state, payload);

    case Actions.DELETE_TEAM:
      return handleDeleteTeamStart(state);

    case Actions.DELETE_TEAM_SUCCESS:
      return handleDeleteTeamSuccess(state, payload);

    case Actions.DELETE_TEAM_FAILURE:
      return handleDeleteTeamFailure(state, payload);

    case Actions.SORT_TEAM_COLUMN:
      return handleColumnSort(state, payload);

    case Actions.GOTO_TEAM_PAGE:
      return handleGotoPage(state, payload);

    case Actions.TEAMS_SEARCH:
      return handleTeamsSearch(state, payload);

    case Actions.TEAMS_SEARCH_SUCCESS:
      return handleTeamsSearchSuccess(state, payload);

    /** Manage Team Members
    ------------------------------------------------------- **/

    /** Team Member Roles
    --------------------------------------------- **/
    case Actions.CHANGE_MEMBER_ROLE_SUCCESS:
      return handleChangeMemberRoleSuccess(state, payload);

    /** Remove Team Members
    --------------------------------------------- **/
    case Actions.REMOVE_TEAM_MEMBER_SUCCESS:
      return handleRemoveTeamMemberSuccess(state, payload);

    /** Add Team Members
    --------------------------------------------- **/
    case Actions.CANCEL_ADD_TEAM_MEMBERS_MODAL:
      return handleCancelAddTeamMembersModal(state, payload);

    /** Successes and Failures
    --------------------------------------------- **/
    case Actions.ADD_TEAM_MEMBER_SUCCESS:
      return handleAddTeamMemberSuccess(state, payload);

    case Actions.ADD_TEAM_MEMBER_FAILURE:
      return handleAddTeamMemberFailure(state, payload);

    /** Alerts
    ------------------------------------------------------- **/
    case Actions.ADD_TO_SELECT_USER_ALERTS:
      return handleAddToSelectUserAlerts(state, payload);

    case Actions.REMOVE_FROM_SELECT_USER_ALERTS:
      return handleRemoveFromSelectUserAlerts(state, payload);

    case Actions.CLEAR_SELECT_USER_ALERTS:
      return handleClearSelectUserAlerts(state);

    /** User Search
    ------------------------------------------------------- **/

    /** Selected Users
    --------------------------------------------- **/
    case Actions.ADD_TO_SELECTED_USERS:
      return handleAddToSelectedUsers(state, payload);

    case Actions.REMOVE_FROM_SELECTED_USERS:
      return handleRemoveFromSelectedUsers(state, payload);

    case Actions.CLEAR_SELECTED_USERS:
      return handleClearSelectedUsers(state);

    /** Autocomplete
    --------------------------------------------- **/
    case Actions.USER_SEARCH_QUERY_CHANGED:
      return handleUserSearchQueryChanged(state, payload);

    case Actions.USER_SEARCH_CLEAR_QUERY:
      return handleUserSearchClearQuery(state, payload);

    /** Results
    --------------------------------------------- **/
    case Actions.USER_SEARCH_RESULTS:
      return handleUserSearchResults(state, payload);

    /** Submission
    ------------------------------------------------------- **/
    case Actions.SUBMIT_ADD_TEAM_MEMBERS_MODAL:
      return handleSubmitAddTeamMembersModal(state);

    /** Default
    ------------------------------------------------------- **/
    default:
      return state;
  }
};

/** Selectors/Accessors
------------------------------------------------------------------------------- **/

/** Teams
--------------------------------------------------------------------- **/

/** Members
----------------------------------------------------------- **/
// member => teamRole
export const getTeamRole = get('teamRole');
// member => boolean
export const isOwner = flow(getTeamRole, eq('owner'));
// member => string
export const getScreenName = get('screenName');
// team => Array[member]
export const getMembers = flow(getOr([], 'members'), mapUsers);
export const getMemberIds = (team) => map(getUserId, getMembers(team));

/** Roles
----------------------------------------------------------- **/
// Array[member] => owner
export const findOwner = find(isOwner);
// team => owner
export const getOwner = flow(getMembers, findOwner, getScreenName);
export const findTeamMemberById = (state, teamId, userId) =>
  flow(
    curryRight(getTeam)(teamId),
    getMembers,
    find((member) => member.id === userId)
  )(state);

/** Form
----------------------------------------------------------- **/
const getTeamForm = get('teamForm');
export const getTeamFormDescription = flow(getTeamForm, get('description'));
export const getTeamFormName = flow(getTeamForm, get('screenName'));
export const getTeamFormErrors = flow(getTeamForm, get('errors'));
export const getTeamFormId = flow(getTeamForm, get('id'));

/** List
----------------------------------------------------------- **/
// state => Array[team]
export const getTeamsList = flow(
  get('teams'),
  map((team) => ({
    ...team,
    owner: getOwner(team)
  }))
);
export const getTeamsCount = get('resultCount');
export const getTeamsSortDirection = get('sortDirection');
export const getTeamsOrderBy = get('orderBy');
export const getTeamsSearchResultCount = get('searchResultCount');
export const getTeamsSearchQuery = get('searchQuery');
export const getTeamsZeroBasedPage = get('zeroBasedPage');
export const getTeamsCurrentPage = flow(getTeamsZeroBasedPage, add(1));
export const getTeamsResultCount = get('resultCount');
export const getTeamsOffset = get('offset');
export const getTeamsQuery = get('query');

/** Instance
----------------------------------------------------------- **/
export const getTeam = (state, id) => flow(getTeamsList, find({ id }))(state);
export const getTeamRoles = get('teamRoles');
export const getTeamName = get('screenName');
export const getTeamNameById = (state, id) => getTeamName(getTeam(state, id));
export const getCurrentTeamId = get('currentTeamId');
export const getCurrentTeam = (state) => getTeam(state, getCurrentTeamId(state));
export const getCurrentTeamMemberIds = (state) => getMemberIds(getCurrentTeam(state));

/** Deleting
------------------------------------------------- **/
export const getDeletingTeamId = get('deletingTeam.deletingTeamId');
export const getDeletingTeamScreenName = get('deletingTeam.deletingTeamScreenName');
export const getDeleteTeamInProgress = get('deletingTeam.inProgress');
export const getDeleteTeamError = get('deletingTeam.error');

/** Add Team Members
--------------------------------------------------------------------- **/
export const getAddMemberErrors = get('addMemberErrors');
export const getAddMemberSuccesses = get('addMemberSuccesses');

/** User Search
----------------------------------------------------------- **/
export const getUserSearch = get('userSearch');
export const getUserSearchResults = flow(getUserSearch, get('results'));
export const getSelectedUsers = flow(getUserSearch, get('selectedUsers'));
export const getSelectedUserIds = flow(getSelectedUsers, map(getUserId));

/** Autocomplete
------------------------------------------------- **/
export const getUserSearchCurrentQuery = flow(getUserSearch, get('currentQuery'));

/** Alerts
----------------------------------------------------------- **/
export const getSelectUserAlerts = flow(getUserSearch, get('selectUserAlerts'));

export default reducer;
