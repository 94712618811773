import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import ResultsTable from 'common/components/ResultsTable';
import DropdownButton, { DropdownItem } from 'common/components/DropdownButton';
import { assign as windowLocationAssign } from 'common/window_location';
import { FeatureFlags } from 'common/feature_flags';

import * as Actions from '../actions';
import * as Selectors from '../../selectors';
import { Link } from 'react-router';
import { TeamPropType } from '../propTypes';
import TeamsPager from './TeamsPager';
import DeleteTeamModal from './DeleteTeamModal';
import TeamEmail from './TeamEmail';
import CSVExportButton from '../../users/components/CSVExportButton';

import { SORT_DIRECTION, SORT_KEYS } from 'common/teams-api';

export class TeamsTable extends Component {
  static propTypes = {
    loadingData: PropTypes.bool.isRequired,
    orderBy: PropTypes.oneOf(Object.values(SORT_KEYS)),
    sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTION)),
    teams: PropTypes.arrayOf(TeamPropType).isRequired,
    onDeleteTeam: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    onShowDeleteTeamModal: PropTypes.func.isRequired,
    onHideDeleteTeamModal: PropTypes.func.isRequired
  };

  renderTeamNameCell = (screenName, { id }) => {
    const { getTeamViewPath } = this.props;
    return <Link to={getTeamViewPath(id)}>{screenName}</Link>;
  };

  renderMembersCell = (members = [], { id }) => {
    const { getTeamViewPath } = this.props;
    return <Link to={getTeamViewPath(id)}>{members.length}</Link>;
  };

  renderActionsCell = (id, { screenName }) => {
    const { getTeamViewPath, onShowDeleteTeamModal } = this.props;
    return (
      <DropdownButton>
        <DropdownItem onClick={() => windowLocationAssign(`${getTeamViewPath(id)}?edit=true`)}>
          {I18n.t('users.actions.edit_team')}
        </DropdownItem>
        <DropdownItem onClick={() => onShowDeleteTeamModal(id, screenName)}>
          {I18n.t('users.actions.delete_team')}
        </DropdownItem>
      </DropdownButton>
    );
  };

  render() {
    const {
      loadingData,
      onSort,
      orderBy,
      sortDirection,
      teams,
      onUserProfile,
      onHideDeleteTeamModal,
      deletingTeamId,
      deletingTeamScreenName
    } = this.props;
    const noResultsMessage = I18n.t('users.no_results');

    return (
      <div>
        {/* If we have an ID of a team that we're deleting, we render the modal to check if they need to be deleted */}
        {deletingTeamId && (
          <DeleteTeamModal
            id={deletingTeamId}
            screenName={deletingTeamScreenName}
            onClose={onHideDeleteTeamModal}
          />
        )}
        <ResultsTable data={teams} rowKey="id" loadingData={loadingData} noResultsMessage={noResultsMessage}>
          <ResultsTable.Column
            isActive={orderBy === SORT_KEYS.SCREEN_NAME}
            sortDirection={sortDirection}
            onSort={() => onSort(SORT_KEYS.SCREEN_NAME)}
            dataIndex="screenName"
            header={I18n.t('users.headers.team_name')}
          >
            {this.renderTeamNameCell}
          </ResultsTable.Column>
          <ResultsTable.Column dataIndex="description" header={I18n.t('users.headers.description')} />
          <ResultsTable.Column
            dataIndex="email"
            header={I18n.t('users.headers.email')}
            isActive={orderBy === SORT_KEYS.EMAIL}
            sortDirection={sortDirection}
            onSort={() => onSort(SORT_KEYS.EMAIL)}
          >
            {(email) => <TeamEmail email={email} />}
          </ResultsTable.Column>
          <ResultsTable.Column dataIndex="members" header={I18n.t('users.headers.members')}>
            {this.renderMembersCell}
          </ResultsTable.Column>
          {!onUserProfile && (
            <ResultsTable.Column dataIndex="id" header={I18n.t('users.headers.actions')}>
              {this.renderActionsCell}
            </ResultsTable.Column>
          )}
        </ResultsTable>
        <div className="results-list-footer">
          <TeamsPager />
          <CSVExportButton classExportType="team" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orderBy: Selectors.getTeamsOrderBy(state),
  sortDirection: Selectors.getTeamsSortDirection(state),
  teams: Selectors.getTeamsList(state),
  loadingData: Selectors.getTeamsLoadingData(state),
  getTeamViewPath: (id) => Selectors.getTeamViewPath(id, state),
  deletingTeamId: Selectors.getDeletingTeamId(state),
  deletingTeamScreenName: Selectors.getDeletingTeamScreenName(state)
});

const mapDispatchToProps = {
  onDeleteTeam: Actions.deleteTeam,
  onSort: Actions.sortTeamColumn,
  onShowDeleteTeamModal: Actions.showDeleteTeamModal,
  onHideDeleteTeamModal: Actions.hideDeleteTeamModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsTable);
