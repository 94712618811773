import PropTypes from 'prop-types';

export const TeamPropType = PropTypes.shape({
  domain_id: PropTypes.number,
  role_name: PropTypes.string,
  team_id: PropTypes.string
});

export const UserPropType = PropTypes.shape({
  email: PropTypes.string,
  flags: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  lastAuthenticatedAt: PropTypes.number,
  roleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  roleName: PropTypes.string,
  screenName: PropTypes.string,
  teamsOwned: PropTypes.arrayOf(TeamPropType),
  teamsMemberOf: PropTypes.arrayOf(TeamPropType)
});
