import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Autocomplete from 'common/components/Autocomplete';
import { renderUserAutocompleteResult } from './UserAutocompleteResult';
import UserRoleFilter from './UserRoleFilter';
import UserStatusFilter from './UserStatusFilter';
import AddUserButton from './AddUserButton';
import * as Actions from '../actions';

class UserSearchBar extends Component {
  static propTypes = {
    onChooseResult: PropTypes.func.isRequired,
    onClearSearch: PropTypes.func.isRequired,
    userAutocomplete: PropTypes.func.isRequired
  };

  render() {
    const { userAutocomplete, onChooseResult, onClearSearch } = this.props;

    const autocompleteOptions = {
      animate: true,
      anonymous: false,
      collapsible: false,
      getSearchResults: (query) => {
        return new Promise((resolve) => userAutocomplete(query, resolve));
      },
      millisecondsBeforeSearch: 100,
      onChooseResult,
      onClearSearch,
      renderResult: renderUserAutocompleteResult
    };

    return (
      <div className="user-search-bar search-bar">
        <div className="user-search-bar-filters">
          <Autocomplete className="autocomplete" {...autocompleteOptions} />
          <UserRoleFilter />
          <UserStatusFilter />
        </div>
        <AddUserButton />
      </div>
    );
  }
}

const mapDispatchToProps = {
  onChooseResult: Actions.queryBasedUserSearch,
  onClearSearch: Actions.clearUserAutocomplete,
  userAutocomplete: Actions.userAutocomplete
};

export default connect(null, mapDispatchToProps)(UserSearchBar);
