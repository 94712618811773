// This file appears to be unused

import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';

import I18n from 'common/i18n';
import { Modal, ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import Button, { VARIANTS } from 'common/components/Button';
import UserSearch, { UserSearchProps } from 'common/components/UserSearch';
import ErrorList from 'common/components/ErrorList';
import { UsersCatalogSearchResults } from 'common/types/users/catalogUsers';

import * as Selectors from '../../selectors.js';
import * as Actions from '../actions';
import * as fromUsers from '../../users/reducers.js';

interface StateProps {
  addedMembers: string[];
  disableModal: boolean;
  errors: any[];
  showModal: boolean;
}

interface DispatchProps {
  cancelModal: () => void;
  submitModal: () => void;
  addToSelectedUsers: (user?: UsersCatalogSearchResults) => void;
  removeFromSelectedUsers: (user: any) => { type: string; payload: { user: any } };
  userSearchQueryChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  userSearchClearQuery: () => void;
}

type AddTeamMembersModalProps = StateProps & DispatchProps & UserSearchProps;

export class AddTeamMembersModal extends Component<AddTeamMembersModalProps> {
  submitModal = () => {
    const { submitModal } = this.props;

    submitModal();
  };

  renderAddedMembers = () => {
    const { addedMembers } = this.props;
    if (isEmpty(addedMembers)) {
      return;
    }
    const listItems = addedMembers.map((displayName) => (
      <li key={displayName}>{I18n.t('users.edit_team.add_team_member_success', { displayName })}</li>
    ));

    return (
      <div className="alert success">
        <ul className="alert-list">{listItems}</ul>
      </div>
    );
  };

  renderUserSearch = () => {
    const {
      addToSelectedUsers,
      currentQuery,
      maxSelectedUsers,
      noResultsMessage,
      placeHolderText,
      results,
      removeFromSelectedUsers,
      selectedUsers,
      userSearchQueryChanged,
      userSearchClearQuery
    } = this.props;

    return (
      <UserSearch
        addToSelectedUsers={addToSelectedUsers}
        currentQuery={currentQuery}
        includeTeamsInHelpText={false}
        maxSelectedUsers={maxSelectedUsers}
        noResultsMessage={noResultsMessage}
        placeHolderText={placeHolderText}
        results={results}
        removeFromSelectedUsers={removeFromSelectedUsers}
        selectedUsers={selectedUsers}
        renderSelectedUserPills={true}
        userSearchQueryChanged={userSearchQueryChanged}
        userSearchClearQuery={userSearchClearQuery}
      />
    );
  };

  render() {
    const { cancelModal, disableModal, errors, showModal, selectedUsers } = this.props;

    const modalProps = {
      className: 'add-team-member',
      fullScreen: false,
      onDismiss: () => {}
    };

    const headerProps = {
      showCloseButton: false,
      title: 'Add Team Members',
      onDismiss: cancelModal
    };

    const disableSubmit = disableModal || !selectedUsers?.length;

    if (showModal) {
      return (
        <Modal {...modalProps}>
          <ModalHeader {...headerProps} />

          <ModalContent>
            {this.renderUserSearch()}
            {/* {this.renderAddedMembers()} */}
            <ErrorList errors={errors} />
          </ModalContent>

          <ModalFooter>
            <div>
              <Button className="cancel-button" onClick={cancelModal} disabled={disableModal}>
                {I18n.t('users.add_new_users.cancel')}
              </Button>
              <Button
                type="submit"
                variant={VARIANTS.PRIMARY}
                className="add-user-confirm"
                disabled={disableSubmit}
                busy={disableModal}
                onClick={this.submitModal}
              >
                {I18n.t('users.edit_team.add_team_members')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any): StateProps => ({
  ...Selectors.getTeamUserSearch(state),
  addedMembers: flow(Selectors.getTeamAddMemberSuccesses, map(fromUsers.getScreenNameFromUser))(state),
  disableModal: Selectors.getDisableAddTeamMembersModal(state),
  errors: Selectors.getTeamAddMemberErrors(state),
  showModal: Selectors.getShowAddTeamMembersModal(state)
});

const mapDispatchToProps: DispatchProps = {
  cancelModal: Actions.cancelAddTeamMembersModal,
  submitModal: Actions.submitAddTeamMembersModal,
  addToSelectedUsers: Actions.addToSelectedUsers,
  removeFromSelectedUsers: Actions.removeFromSelectedUsers,
  userSearchQueryChanged: (event: ChangeEvent<HTMLInputElement>) =>
    Actions.userSearchQueryChanged(event.target.value, { only: 'site_members' }),
  userSearchClearQuery: () => Actions.userSearchClearQuery()
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTeamMembersModal);
