import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import Autocomplete from 'common/components/Autocomplete';

import EditTeamModal from './EditTeamModal';
import { renderTeamsAutocompleteResult } from './TeamsAutocompleteResult';
import * as Actions from '../actions';
import * as Selectors from '../../selectors';

class TeamSearchBar extends Component {
  static propTypes = {
    showEditTeamModal: PropTypes.func.isRequired
  };

  render() {
    const { showEditTeamModal, showingTeamEditModal, dismissEditTeamModal, getTeamViewPath } = this.props;
    const autocompleteOptions = {
      animate: true,
      anonymous: false,
      collapsible: false,
      getSearchResults: (query) => {
        return new Promise((resolve) => this.props.teamsAutocomplete(query, resolve));
      },
      millisecondsBeforeSearch: 100,
      onChooseResult: this.props.onChooseResult,
      onClearSearch: this.props.onClearSearch,
      renderResult: renderTeamsAutocompleteResult
    };

    return (
      <div className="team-search-bar search-bar">
        <Autocomplete className="autocomplete" {...autocompleteOptions} />
        <Button variant="primary" onClick={showEditTeamModal} className="add-team-button">
          {I18n.t('users.add_new_team.add_team_label')}
        </Button>
        {showingTeamEditModal && (
          <EditTeamModal dismissModal={dismissEditTeamModal} getTeamViewPath={getTeamViewPath} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showingTeamEditModal: Selectors.getShowEditTeamModal(state),
  getTeamViewPath: (teamId) => `${Selectors.getTeamsAdminPath(state)}/${teamId}`
});

const mapDispatchToProps = {
  showEditTeamModal: Actions.showEditTeamModal,
  onChooseResult: Actions.teamsSearch,
  onClearSearch: Actions.teamsSearch,
  teamsAutocomplete: Actions.teamsAutocomplete,
  dismissEditTeamModal: Actions.hideEditTeamModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamSearchBar);
