import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import PagerGroup from 'common/components/PagerGroup';

import * as Actions from '../actions';
import * as Selectors from '../../selectors';

const mapStateToProps = (state) => {
  const searchResultCount = Selectors.getTeamsSearchResultCount(state);

  return {
    currentPage: Selectors.getTeamsCurrentPage(state),
    resultCount: isUndefined(searchResultCount) ? Selectors.getTeamsResultCount(state) : searchResultCount,
    resultsPerPage: Selectors.getTeamsResultsLimit(state)
  };
};

const mapDispatchToProps = {
  onChangePage: Actions.gotoTeamPage
};
export default connect(mapStateToProps, mapDispatchToProps)(PagerGroup);
