import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Button from 'common/components/Button';
import { invokeArgsMapValues } from 'common/functional_helpers';

import GrantPlatformAdminAccessModal from './GrantPlatformAdminAccessModal';
import * as Selectors from '../../selectors';
import * as Actions from '../actions';

class PlatformAdminAccessToolbar extends Component {
  static propTypes = {
    handleShowModal: PropTypes.func,
    showModal: PropTypes.bool
  };

  render() {
    const { handleShowModal, showModal } = this.props;
    return (
      <div className="platform-admin-access-toolbar">
        <Button variant="primary" disabled={showModal} onClick={handleShowModal}>
          {I18n.t('users.platform_admin_access.grant_platform_admin_access')}
        </Button>
        <GrantPlatformAdminAccessModal />
      </div>
    );
  }
}

const mapStateToProps = invokeArgsMapValues({
  showModal: Selectors.PlatformAdminAccess.showAddPlatformAdminAccessModal
});

const mapDispatchToProps = {
  handleShowModal: Actions.showAddPlatformAdminAccessModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformAdminAccessToolbar);
