import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Result } from 'common/components/Autocomplete/components/Results/Result';
import 'common/components/Autocomplete/components/Results/results.scss';

export class UserAutocompleteResult extends Result {
  static propTypes = {
    onChooseResult: PropTypes.func.isRequired,
    onResultFocusChanged: PropTypes.func.isRequired,
    onQueryChanged: PropTypes.func.isRequired,
    onResultsVisibilityChanged: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    matches: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    focused: PropTypes.bool.isRequired,
    name: PropTypes.string
  };

  // Returns array of `user.screen_name` & `user.email` prop fragments decorated with <span class="highlight">
  // around the matches specified in the `matches` prop.
  displayTitle = () => {
    const { user, matches } = this.props;
    const { screen_name: screenName, email } = user;

    const displayTitleFragments = [];

    // screen_name match
    const screenNameMatch = matches.find((match) => {
      return match.field === 'screen_name';
    });
    if (!_.isUndefined(screenNameMatch)) {
      displayTitleFragments.push(screenName.substring(0, _.get(screenNameMatch.offsets[0], 'start')));
      screenNameMatch.offsets.forEach((offset, index) => {
        displayTitleFragments.push(
          <span className="highlight" key={`name_${index}`}>
            {screenName.substring(offset.start, offset.length + offset.start)}
          </span>
        );

        const nextMatch = screenNameMatch.offsets[index + 1];
        const nextMatchStart = nextMatch ? nextMatch.start : undefined;
        displayTitleFragments.push(screenName.substring(offset.length + offset.start, nextMatchStart));
      });
    } else {
      displayTitleFragments.push(screenName);
    }

    displayTitleFragments.push(' - ');

    // email match
    const emailMatch = matches.find((match) => {
      return match.field === 'email';
    });
    if (!_.isUndefined(emailMatch)) {
      displayTitleFragments.push(email.substring(0, _.get(emailMatch.offsets[0], 'start')));
      emailMatch.offsets.forEach((offset, index) => {
        displayTitleFragments.push(
          <span className="highlight" key={`email_${index}`}>
            {email.substring(offset.start, offset.length + offset.start)}
          </span>
        );

        const nextMatch = emailMatch.offsets[index + 1];
        const nextMatchStart = nextMatch ? nextMatch.start : undefined;
        displayTitleFragments.push(email.substring(offset.length + offset.start, nextMatchStart));
      });
    } else {
      displayTitleFragments.push(email);
    }

    return displayTitleFragments;
  };
}

export const renderUserAutocompleteResult = (
  result,
  index,
  focused,
  onChooseResult,
  onResultsVisibilityChanged,
  onResultFocusChanged,
  onQueryChanged
) => {
  const { matches, user } = result;

  return (
    <UserAutocompleteResult
      key={user.id}
      matches={matches}
      name={user.screen_name}
      user={user}
      index={index}
      focused={focused}
      onChooseResult={onChooseResult}
      onResultsVisibilityChanged={onResultsVisibilityChanged}
      onResultFocusChanged={onResultFocusChanged}
      onQueryChanged={onQueryChanged}
    />
  );
};
