import curry from 'lodash/fp/curry';
import toString from 'lodash/fp/toString';
import { TEAM_ROLES } from 'common/teams/constants';

/**
 * Get the display name for a role
 * @param {object} role Role to get name for
 * @param {array} rolesList List of all roles, including default and custom roles
 * @param {object} I18n Translation getter thing
 */
export const getRoleDisplayName = (role, rolesList, I18n) =>
  role && role.isDefault
    ? I18n.t(`roles.default_roles.${role.name}.name`)
    : getCustomRoleName(role, rolesList, I18n);

/**
 * If the role is not default, but has the same name as a default role,
 * we append (Custom) to the end of it to differentiate the two
 * @param {object} customRole Custom role to get name for
 * @param {array} rolesList List of all roles, including default and custom roles
 * @param {object} I18n Translation getter thing
 */
export const getCustomRoleName = (customRole, rolesList, I18n) => {
  if (!customRole) {
    console.warn('getCustomRoleName was called with no customRole');
    return;
  }

  return rolesList.some(
    (otherRole) => otherRole.isDefault && otherRole.name.toLowerCase() === customRole.name.toLowerCase()
  )
    ? `${customRole.name} (${I18n.t('users.roles.custom')})`
    : customRole.name;
};

/**
 * Counts the number of owners inside of a team to calculate when the ability to edit members is
 * enabled
 * @param {array} members List of all members in team
 */
export const countNumberOfOwners = (members) => {
  if (members.length <= 0) {
    return 0;
  }

  return members.reduce(
    (total, member) => total + (member.teamRole === TEAM_ROLES.OWNER ? 1 : 0),
    0 // initial value
  );
};

export const makeZeroBasedPageFromPager = (page) => Math.max(0, page - 1);

export const roleIdsEqual = curry((id1, id2) => toString(id1) === toString(id2));

// Note that role.id is a Number but roleId is a String, so using coersive comparison operator
export const findRole = (roleId, roleList) => roleList.find((role) => role.id == roleId);
