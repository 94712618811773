import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import orderBy from 'lodash/fp/orderBy';
import overSome from 'lodash/fp/overSome';
import pick from 'lodash/fp/pick';
import { AccessTimes } from './components/AccessTimeChooser';

import * as Actions from './actions';

const pickFormFields = pick(['accessTime', 'email']);

const sortGrants = (grants) =>
  orderBy(
    (grant) => [getGrantAccessRevokedAt(grant) || Number.MAX_SAFE_INTEGER, getGrantAccessExpiresAt(grant)],
    ['desc', 'asc'],
    grants
  );

export const initialState = {
  accessTime: AccessTimes.DAYS_1,
  email: '',
  loadingData: false,
  platformAdminGrants: [],
  grantingPlatformAdminAccess: false,
  showAddPlatformAdminAccessModal: false
};

const handleLoadPlatformAdminsSuccess = (state, { platformAdminGrants }) => ({
  ...state,
  loadingData: false,
  platformAdminGrants: sortGrants(platformAdminGrants)
});

const handleShowAddPlatformAdminAccessModal = (state) => ({
  ...state,
  grantingPlatformAdminAccess: false,
  showAddPlatformAdminAccessModal: true
});

const handleHideAddPlatformAdminAccessModal = (state) => ({
  ...state,
  ...pickFormFields(initialState),
  grantingPlatformAdminAccess: false,
  showAddPlatformAdminAccessModal: false
});

const handleChangeAccessTime = (state, { accessTime }) => ({
  ...state,
  accessTime
});

const handleChangePlatformAdminEmail = (state, { email }) => ({
  ...state,
  email
});

const handlerSubmitAddPlatformAdminAccessModal = (state) => ({
  ...state,
  grantingPlatformAdminAccess: true
});

const handleGrantAdminAccessSuccess = (state, { grant }) => ({
  ...state,
  platformAdminGrants: sortGrants([...state.platformAdminGrants, grant])
});

const handleRevokeAdminAccessSuccess = (state, { id }) => ({
  ...state,
  platformAdminGrants: filter((grant) => grant.id !== id, state.platformAdminGrants)
});

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.CHANGE_ACCESS_TIME:
      return handleChangeAccessTime(state, payload);

    case Actions.CHANGE_PLATFORM_ADMIN_EMAIL:
      return handleChangePlatformAdminEmail(state, payload);

    case Actions.SHOW_ADD_PLATFORM_ADMIN_ACCESS_MODAL:
      return handleShowAddPlatformAdminAccessModal(state);

    case Actions.HIDE_ADD_PLATFORM_ADMIN_ACCESS_MODAL:
      return handleHideAddPlatformAdminAccessModal(state);

    case Actions.SUBMIT_ADD_PLATFORM_ADMIN_ACCESS_MODAL:
      return handlerSubmitAddPlatformAdminAccessModal(state);

    case Actions.GRANT_ADMIN_ACCESS_SUCCESS:
      return handleGrantAdminAccessSuccess(state, payload);

    case Actions.LOAD_PLATFORM_ADMINS_SUCCESS:
      return handleLoadPlatformAdminsSuccess(state, payload);

    case Actions.REVOKE_ADMIN_ACCESS_SUCCESS:
      return handleRevokeAdminAccessSuccess(state, payload);

    default:
      return state;
  }
};

/** Selectors */
export const getAccessTime = get('accessTime');
export const getEmail = get('email');
export const getGrantingPlatformAdminAccess = get('grantingPlatformAdminAccess');
export const getLoadingData = get('loadingData');
export const getPlatformAdminGrants = get('platformAdminGrants');
export const getShowAddPlatformAdminAccessModal = get('showAddPlatformAdminAccessModal');
export const getGrantAccessRevokedAt = get('accessRevokedAt');
export const getGrantAccessExpiresAt = get('accessExpiresAt');

export const getDisableModal = overSome(getLoadingData, getGrantingPlatformAdminAccess);

export const findAdminAccessGrantById = (id) => flow(getPlatformAdminGrants, find({ id }));

export default reducer;
