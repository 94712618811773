import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import I18n from 'common/i18n';
import Pager from 'common/components/Pager';
import ResultsTable from 'common/components/ResultsTable';
import { SORT_DIRECTION, SORT_KEYS } from 'common/users-api';
import { countNumberOfOwners } from '../../utils';
import { FeatureFlags } from 'common/feature_flags';

import MemberScreenName from './MemberScreenName';
import MemberEditControl from './MemberEditControl';
import * as Actions from '../actions';
import * as Selectors from '../../selectors';
import TeamMemberCSVExportButton from './TeamMemberCSVExportButton';
import { string } from 'prop-types';

// TODO: Add sorting/pagination once this data is available in catalog service

class TeamListTable extends Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    loadingData: PropTypes.bool,
    members: PropTypes.array,
    onChangePage: PropTypes.func.isRequired,
    resultCount: PropTypes.number,
    resultsPerPage: PropTypes.number,
    teamId: PropTypes.string
  };

  static defaultProps = {
    members: [],
    teamName: string
  };

  renderScreenNameCell = (screenName, { displayName, id, disabled }) => {
    return (
      <span>
        <MemberScreenName screenName={screenName || displayName} id={id} />
        {disabled && <span className="grey-badge">Disabled</span>}
      </span>
    );
  };

  renderEditControl = (userId, { displayName, teamRole, screenName, ownerCount }) => (
    <MemberEditControl
      userId={userId}
      ownerCount={ownerCount}
      teamRole={teamRole}
      displayName={screenName || displayName}
      teamId={this.props.teamId}
    />
  );

  renderTeamRoleCell = (teamRole) => I18n.t(`users.team_roles.${teamRole}`);

  render() {
    const {
      currentPage,
      loadingData,
      members,
      onChangePage,
      // onSort,
      // orderBy,
      resultCount,
      resultsPerPage
      // sortDirection,
    } = this.props;
    const noResultsMessage = I18n.t('users.no_results');
    const rowClassFunction = (member) =>
      member.disabled ? 'member-account-disabled' : 'member-account-active';
    const ownerCount = countNumberOfOwners(members);
    members.forEach((member) => (member.ownerCount = ownerCount));
    return (
      <div>
        <ResultsTable
          data={members}
          rowKey="id"
          loadingData={loadingData}
          noResultsMessage={noResultsMessage}
          rowClassFunction={rowClassFunction}
          id={'team-list-table'}
        >
          <ResultsTable.Column
            // isActive={orderBy === SORT_KEYS.SCREEN_NAME}
            // sortDirection={sortDirection}
            // onSort={() => onSort(SORT_KEYS.SCREEN_NAME)}
            header={I18n.t('users.headers.name')}
            dataIndex="screenName"
          >
            {this.renderScreenNameCell}
          </ResultsTable.Column>
          <ResultsTable.Column
            // isActive={orderBy === SORT_KEYS.EMAIL}
            // sortDirection={sortDirection}
            // onSort={() => onSort(SORT_KEYS.EMAIL)}
            header={I18n.t('users.headers.email')}
            dataIndex="email"
          />
          <ResultsTable.Column
            // isActive={orderBy === SORT_KEYS.ROLE_NAME}
            // sortDirection={sortDirection}
            // onSort={() => onSort(SORT_KEYS.ROLE_NAME)}
            header={I18n.t('users.headers.role')}
            dataIndex="teamRole"
          >
            {this.renderTeamRoleCell}
          </ResultsTable.Column>
          <ResultsTable.Column
            header={I18n.t('users.headers.actions')}
            dataIndex="id"
            dataClassName="action-menu-cell"
          >
            {this.renderEditControl}
          </ResultsTable.Column>
        </ResultsTable>
        <div className="results-list-footer">
          <Pager
            changePage={onChangePage}
            currentPage={currentPage}
            resultCount={resultCount}
            resultsPerPage={resultsPerPage}
          />
          <TeamMemberCSVExportButton teamName={this.props.teamName} data={members} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: 1, // Selectors.getTeamsCurrentPage(state),
  loadingData: Selectors.getTeamsLoadingTeam(state),
  orderBy: SORT_KEYS.SCREEN_NAME, // Selectors.getTeamsOrderBy(state),
  resultsPerPage: 1000, // Selectors.getTeamsResultsLimit(state),
  sortDirection: SORT_DIRECTION.ASC // Selectors.getTeamsSortDirection(state),
});

const mapDispatchToProps = {
  changeMemberRole: Actions.changeMemberRole,
  onChangePage: () => console.log('onChangePage'), // Actions.gotoPage,
  onSort: () => console.log('onSort') // Actions.sortColumn
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamListTable);
