import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

interface TeamOwnsAssetsWarningProps {
  /** UID (4x4) of the team to delete */
  id: string;
}

/** Rendered when trying to delete a team that owns assets. */
const TeamOwnsAssetsWarning: FunctionComponent<TeamOwnsAssetsWarningProps> = ({ id }) => (
  <div>
    {/* "This team owns one or more assets" */}
    <p>{I18n.t('users.actions.delete_team_info.team_owns_assets')}</p>

    {/*
      "See the list of assets this team owns" link.
      This will take the user to the asset manager showing the list of assets that this team owns.
    */}
    <a href={`/admin/assets?ownerId=${id}`} target="_blank" rel="noopener noreferrer">
      {I18n.t('users.actions.delete_team_info.see_list_of_assets')}
      <SocrataIcon className="delete-team-modal-external-icon" name={IconName.External} />
    </a>

    {/* `mailto:` link to contact support */}
    <p
      dangerouslySetInnerHTML={{
        __html: I18n.t('users.actions.delete_team_info.additional_assistance_html')
      }}
    />
  </div>
);

export default TeamOwnsAssetsWarning;
