import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import Button from 'common/components/Button';
import * as Selectors from '../../selectors';
import * as Actions from '../actions';
import FeatureFlags from 'common/feature_flags';
import EditTeamModal from './EditTeamModal';
import TeamListTable from './TeamListTable';
import TeamEmail from './TeamEmail';
import AddTeamMembersModalV1 from './AddTeamMembersModal';
import AddTeamMembersModalV2 from './AddTeamMembersModal/AddTeamMembersModal';

class TeamView extends Component {
  UNSAFE_componentWillMount() {
    const { teamViewNavigation, teamId } = this.props;
    teamViewNavigation(teamId);
  }

  state = {
    /**
     * This starts out as false, and if the page is loaded with `?edit=true`
     * then the edit modal will open up and this will be set to true.
     *
     * This is because the way that detecting when to open the edit modal is a little weird
     * (see also UNSAFE_componentWillReceiveProps)
     */
    handledEditQueryParam: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { team, showEditTeamModal, showingTeamEditModal } = nextProps;

    // if we have a team and the URL includes `?edit=true` then we open the modal
    // but... we don't have the team on this component's first render! So we have to wait for it to get
    // passed in via props
    if (
      // also, "props.team" starts off as an empty object, so we check for the `id` field here
      // which will always exist on an existing team
      team.id &&
      !showingTeamEditModal
    ) {
      const { handledEditQueryParam } = this.state;

      const urlParams = new URLSearchParams(window.location.search);
      const shouldEdit = urlParams.get('edit');

      if (shouldEdit === 'true' && !handledEditQueryParam) {
        showEditTeamModal();

        this.setState({
          handledEditQueryParam: true
        });
      }
    }
  }

  renderAddTeamMemberModal = () => {
    const { team } = this.props;

    if (FeatureFlags.valueOrDefault('add_team_members_modal_v2', false)) {
      return <AddTeamMembersModalV2 teamId={team.id} />;
    } else {
      return <AddTeamMembersModalV1 teamId={team.id} />;
    }
  };

  render() {
    const {
      addTeamMembers,
      showEditTeamModal,
      team,
      teamsAdminPath,
      showingTeamEditModal,
      dismissEditTeamModal
    } = this.props;

    const { description, email, id, screenName, members } = team;

    return (
      <div>
        {showingTeamEditModal && <EditTeamModal currentTeam={team} dismissModal={dismissEditTeamModal} />}
        <Link to={teamsAdminPath} className="team-view-back">
          <SocrataIcon name="arrow-left" className="team-view-back-icon" />
          <span>{I18n.t('users.edit_team.back_to_all_teams')}</span>
        </Link>
        <div className="edit-team-info">
          <h2>
            <span className="team-name">{screenName}</span>
            <span className="team-name-edit" onClick={showEditTeamModal}>
              <SocrataIcon name="edit" className="team-edit-icon" />
            </span>
          </h2>
          <div className="team-description">{description}</div>
          <div className="team-email">
            <TeamEmail email={email} />
          </div>
        </div>
        <div className="team-search-bar search-bar">
          <div> </div>
          <Button variant="primary" onClick={addTeamMembers}>
            {I18n.t('users.edit_team.add_team_members')}
          </Button>
          {this.renderAddTeamMemberModal()}
        </div>
        <TeamListTable teamId={id} members={members} teamName={screenName} />
      </div>
    );
  }
}

const mapStateToProps = (state, { params: { teamId } }) => ({
  teamsAdminPath: Selectors.getTeamsAdminPath(state),
  teamId,
  team: Selectors.getTeam(state, teamId) || {},
  showingTeamEditModal: Selectors.getShowEditTeamModal(state)
});

const mapDispatchToProps = (dispatch, { params: { teamId } }) =>
  bindActionCreators(
    {
      addTeamMembers: () => Actions.addTeamMembers(teamId),
      teamViewNavigation: Actions.teamViewNavigation,
      showEditTeamModal: () => Actions.showEditTeamModal(),
      dismissEditTeamModal: () => Actions.hideEditTeamModal()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(TeamView);
