import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import eq from 'lodash/fp/eq';
import stubTrue from 'lodash/fp/stubTrue';
import { all, call, put, select, takeLatest, delay } from 'redux-saga/effects';

import * as PlatformAdminAccessApi from 'common/core-platform-admin-access-api';
import { ERROR_CODES as API_ERROR_CODES } from 'common/core-platform-admin-access-api';
import { invokeArgsMapValues } from 'common/functional_helpers';

import { accessTimeToDuration } from './components/AccessTimeChooser';
import * as GlobalActions from '../actions';
import * as Actions from './actions';
import * as Selectors from '../selectors';

export function* loadPlatformAdmins() {
  try {
    const platformAdminGrants = yield call(PlatformAdminAccessApi.getAll);
    yield put(Actions.loadPlatformAdminsSuccess(platformAdminGrants));
  } catch (error) {
    console.warn('Unable to load platform admins, using empty list.', error);
    yield put(Actions.loadPlatformAdminsFailure(error));
  }
}

export function* cancelAddPlatformAdminAccessModal() {
  yield put(Actions.hideAddPlatformAdminAccessModal());
}

async function getErrorCodeFromError(error) {
  try {
    const json = await error.response.json();
    return json.code;
  } catch (extractionError) {
    console.warn('Unable to extract error code from error', { error, extractionError });
  }
}

export function* submitAddPlatformAdminAccessModal() {
  const data = yield select(
    invokeArgsMapValues({
      accessTime: Selectors.PlatformAdminAccess.getAccessTime,
      email: Selectors.PlatformAdminAccess.getEmail
    })
  );
  const { accessTime, email } = data;

  try {
    const [newGrant] = yield all([
      call(PlatformAdminAccessApi.grantAccess, email, accessTimeToDuration(accessTime)),
      delay(2500)
    ]);
    yield put(Actions.grantAdminAccessSuccess(newGrant));
    yield put(
      GlobalActions.showLocalizedSuccessNotification(
        'users.platform_admin_access.notifications.access_granted',
        { email }
      )
    );
  } catch (error) {
    const errorCode = yield call(getErrorCodeFromError, error);
    const errorTranslationKey = cond([
      [
        eq(API_ERROR_CODES.USER_ALREADY_HAS_GRANT),
        constant('users.platform_admin_access.notifications.user_already_has_grant')
      ],
      [
        eq(API_ERROR_CODES.USER_NOT_FOUND),
        constant('users.platform_admin_access.notifications.user_not_found')
      ],
      [stubTrue, constant('users.platform_admin_access.notifications.unable_to_grant_access')]
    ])(errorCode);
    yield put(GlobalActions.showLocalizedErrorNotification(errorTranslationKey, { email }));
  }
  yield put(Actions.hideAddPlatformAdminAccessModal());
}

export function* revokeAdminAccess({ payload: { id } }) {
  const grant = yield select(Selectors.PlatformAdminAccess.findAdminAccessGrantById(id));
  const {
    targetUser: { email }
  } = grant;
  try {
    yield call(PlatformAdminAccessApi.revokeAccess, email);
    yield put(Actions.revokeAdminAccessSuccess(id));
    yield put(
      GlobalActions.showLocalizedSuccessNotification(
        'users.platform_admin_access.notifications.access_revoked',
        { email }
      )
    );
  } catch (error) {
    console.warn('Error revoking grant:', { grant }, error);
    yield put(
      GlobalActions.showLocalizedErrorNotification(
        'users.platform_admin_access.notifications.unable_to_revoke_access',
        { email }
      )
    );
  }
}

export default [
  takeLatest(Actions.LOAD_PLATFORM_ADMINS, loadPlatformAdmins),
  takeLatest(Actions.REVOKE_ADMIN_ACCESS, revokeAdminAccess),
  takeLatest(Actions.SUBMIT_ADD_PLATFORM_ADMIN_ACCESS_MODAL, submitAddPlatformAdminAccessModal),
  takeLatest(Actions.CANCEL_ADD_PLATFORM_ADMIN_ACCESS_MODAL, cancelAddPlatformAdminAccessModal)
];
