import { combineSelectors } from 'combine-selectors-redux';
import curry from 'lodash/fp/curry';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import includes from 'lodash/fp/includes';
import over from 'lodash/fp/over';
import overEvery from 'lodash/fp/overEvery';
import overSome from 'lodash/fp/overSome';

import { tupleProduct } from 'common/functional_helpers';

import * as fromRoles from './roles/reducers';
import * as fromUsers from './users/reducers';
import * as fromInvitedUsers from './invitedUsers/reducers';
import * as fromUi from './ui/reducers';
import * as PlatformAdminAccessSelectors from './platformAdminAccess/reducers';
import { selectors as teamsSelectors } from './teams/reducers';
import { RIGHTS } from 'common/teams/constants';

/** Config State Selectors **/
export const getInvitedUsersAdminPath = get('config.routes.invitedUsersAdminPath');
export const getTeamsAdminPath = get('config.routes.teamsAdminPath');
export const getTeamViewPath = curry((id, state) => `${getTeamsAdminPath(state)}/${id}`);
export const getUsersAdminPath = get('config.routes.usersAdminPath');
export const getPlatformAdminAccessPath = get('config.routes.platformAdminAccessPath');
export const getUsersCsvUrl = get('config.routes.usersCsvUrl');
export const getUsersResultsLimit = get('config.usersResultsLimit');
export const getTeamsCsvUrl = get('config.routes.teamsCsvUrl');
export const getTeamsResultsLimit = get('config.teamsResultsLimit');
export const getDomain = get('config.domain');
export const getDefaultMemberRoleId = get('config.defaultMemberRoleId');
export const getStrictPermissions = get('config.strictPermissions');
export const getEnableTeams = get('config.enableTeams');
export const getRestrictSuperadminAccess = get('config.restrictSuperadminAccess');
export const getCurrentUserId = get('config.currentUser.id');
export const getManageableRoles = get('config.manageableRoles');
export const currentUserHasRight = (right) => flow(getOr([], 'config.currentUser.rights'), includes(right));
export const getShowTeams = overEvery([currentUserHasRight(RIGHTS.MANAGE_TEAMS), getEnableTeams]);

export const getShowUsers = currentUserHasRight(RIGHTS.MANAGE_USERS);
export const getUserCanManageSuperadminAccess = currentUserHasRight(RIGHTS.MANAGE_SUPERADMIN_ACCESS);
export const getShowPlatformAdminAccess = overEvery([
  getRestrictSuperadminAccess,
  getUserCanManageSuperadminAccess
]);

/** UI State Selectors **/
export const getUiState = get('ui');
/**
 * TODO: EN-21622
 * What is this?  While factoring out these selectors to this file, and running the karma tests,
 * I would encounter an error : `Uncaught TypeError: Expected a function` - originating from the `flow`
 * calls below (but only for those that were composing with `fromUi` functions! wat)
 * Through some debugging, I found that this only happened during karma start up, not when the tests
 * were actually run, nor when run normally in a browser.  For the meantime, we will guard against these
 * functions being undefined with this function, and try to track down the underlying issue with
 * karma/webpack/the GOP when not under a time constraint.
 *
 * @param fn
 * @returns {function(): void}
 */
const safetyWrapper = (fn) =>
  fn ? flow(getUiState, fn) : () => console.warn('Something is wrong. See EN-21622');
export const getNotificationContent = safetyWrapper(fromUi.getNotificationContent);
export const getNotificationType = safetyWrapper(fromUi.getNotificationType);
export const getShowNotification = safetyWrapper(fromUi.getShowNotification);
export const getShowAddUsersModal = safetyWrapper(fromUi.getShowAddUsersModal);
export const getAddUsersModalDisabled = safetyWrapper(fromUi.getAddUsersModalDisabled);

/** User State Selectors **/
export const getUserState = get('users');
export const getFilters = get('filters');
export const getUsersCurrentPage = flow(getUserState, fromUsers.getCurrentPage);
export const getUsersOrderBy = flow(getUserState, fromUsers.getOrderBy);
export const getUsersSortDirection = flow(getUserState, fromUsers.getSortDirection);
export const getUsersResultCount = flow(getUserState, fromUsers.getResultCount);
export const getUsers = flow(getUserState, fromUsers.getUsers);
export const getUsersQuery = flow(getUserState, fromUsers.getQuery);
export const getAutocompleteUser = get('autocomplete.searchResults.results.[0].user');
export const getUsersZeroBasedPath = flow(getUserState, fromUsers.getZeroBasedPage);
export const getUsersOffset = flow(over([getUsersZeroBasedPath, getUsersResultsLimit]), tupleProduct);
export const getUsersLoadingData = flow(getUserState, fromUsers.getLoadingData);
export const getUserSearchResultCount = flow(getUserState, fromUsers.getSearchResultCount);
export const getAddUsersFormEmails = flow(getUserState, fromUsers.getAddUsersFormEmails);
export const getAddUsersFormRoleId = flow(getUserState, fromUsers.getAddUsersFormRoleId);
export const getAddUsersFormErrors = flow(getUserState, fromUsers.getAddUsersFormErrors);
export const getUserStatusFilter = flow(getUserState, fromUsers.getUserStatusFilter);

/** Roles State Selectors **/
export const getRolesState = get('roles');
export const getRoles = flow(getRolesState, fromRoles.getRoles);
export const getRolesLoading = flow(getRolesState, fromRoles.getRolesLoading);
export const getUserRoleFilter = flow(getRolesState, fromRoles.getUserRoleFilter);

/** Invited Users State Selectors **/
export const getInvitedUsersState = get('invitedUsers');
export const getInvitedUsers = flow(getInvitedUsersState, fromInvitedUsers.getInvitedUsers);
export const getInvitedUserCount = flow(getInvitedUsersState, fromInvitedUsers.getInvitedUserCount);
export const getInvitedUsersLoading = flow(getInvitedUsersState, fromInvitedUsers.getInvitedUsersLoading);
export const getInvitedUserEmailById = (id) =>
  flow(getInvitedUsersState, fromInvitedUsers.getInvitedUserEmailById(id));

/** Socrata Support Admin Selectors **/
export const getPlatformAdminAccessState = get('platformAdminAccess');
const withPlatformAdminAccessState = (selector) => flow(getPlatformAdminAccessState, selector);
export const PlatformAdminAccess = {
  findAdminAccessGrantById: (id) =>
    withPlatformAdminAccessState(PlatformAdminAccessSelectors.findAdminAccessGrantById(id)),
  getAccessTime: withPlatformAdminAccessState(PlatformAdminAccessSelectors.getAccessTime),
  getDisableModal: withPlatformAdminAccessState(PlatformAdminAccessSelectors.getDisableModal),
  getEmail: withPlatformAdminAccessState(PlatformAdminAccessSelectors.getEmail),
  getGrantingPlatformAdminAccess: withPlatformAdminAccessState(
    PlatformAdminAccessSelectors.getGrantingPlatformAdminAccess
  ),
  getPlatformAdminGrants: withPlatformAdminAccessState(PlatformAdminAccessSelectors.getPlatformAdminGrants),
  getLoadingData: withPlatformAdminAccessState(PlatformAdminAccessSelectors.getLoadingData),
  showAddPlatformAdminAccessModal: withPlatformAdminAccessState(
    PlatformAdminAccessSelectors.getShowAddPlatformAdminAccessModal
  )
};

/** Teams State Selectors **/
export const selectors = combineSelectors({
  teams: teamsSelectors
});

export const getTeamsList = selectors.teams.teams.getTeamsList;
export const getTeamsLoadingData = selectors.teams.ui.getLoadingData;
export const getTeamsLoadingTeam = selectors.teams.ui.getLoadingTeam;
export const getShowEditTeamModal = selectors.teams.ui.getShowEditTeamModal;
export const getDisableEditTeamModal = selectors.teams.ui.getDisableEditTeamModal;
export const getShowAddTeamMembersModal = selectors.teams.ui.getShowAddTeamMembersModal;
export const getDisableAddTeamMembersModal = selectors.teams.ui.getDisableAddTeamMembersModal;
export const getTeamFormId = selectors.teams.teams.getTeamFormId;
export const getTeamFormName = selectors.teams.teams.getTeamFormName;
export const getTeamFormDescription = selectors.teams.teams.getTeamFormDescription;
export const getTeamFormErrors = selectors.teams.teams.getTeamFormErrors;
export const getTeam = selectors.teams.teams.getTeam;
export const getCurrentTeam = selectors.teams.teams.getCurrentTeam;
export const getTeamNameById = selectors.teams.teams.getTeamNameById;
export const getTeamRoles = selectors.teams.teams.getTeamRoles;
export const getTeamsCount = selectors.teams.teams.getTeamsCount;
export const findTeamMemberById = selectors.teams.teams.findTeamMemberById;
export const getTeamUserSearch = selectors.teams.teams.getUserSearch;
export const getCurrentTeamMemberIds = selectors.teams.teams.getCurrentTeamMemberIds;

export const getTeamAddMemberErrors = selectors.teams.teams.getAddMemberErrors;
export const getTeamAddMemberSuccesses = selectors.teams.teams.getAddMemberSuccesses;
export const getTeamAddMemberSelectedUsers = selectors.teams.teams.getSelectedUsers;
export const getTeamSelectUserAlerts = selectors.teams.teams.getSelectUserAlerts;
export const getTeamUserSearchCurrentQuery = selectors.teams.teams.getUserSearchCurrentQuery;
export const getTeamUserSearchResults = selectors.teams.teams.getUserSearchResults;

export const getDeletingTeamId = selectors.teams.teams.getDeletingTeamId;
export const getDeletingTeamScreenName = selectors.teams.teams.getDeletingTeamScreenName;
export const getDeleteTeamInProgress = selectors.teams.teams.getDeleteTeamInProgress;
export const getDeleteTeamError = selectors.teams.teams.getDeleteTeamError;

export const getTeamsSearchResultCount = selectors.teams.teams.getTeamsSearchResultCount;
export const getTeamsSearchQuery = selectors.teams.teams.getTeamsSearchQuery;
export const getTeamsSortDirection = selectors.teams.teams.getTeamsSortDirection;
export const getTeamsOrderBy = selectors.teams.teams.getTeamsOrderBy;
export const getTeamsCurrentPage = selectors.teams.teams.getTeamsCurrentPage;
export const getTeamsResultCount = selectors.teams.teams.getTeamsResultCount;
export const getTeamsZeroBasedPage = selectors.teams.teams.getTeamsZeroBasedPage;
export const getTeamsOffset = flow(over([getTeamsZeroBasedPage, getTeamsResultsLimit]), tupleProduct);
