import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import I18n from 'common/i18n';

import * as Selectors from '../../selectors';

export class CSVExportButton extends Component {
  render() {
    const { csv } = this.props;
    return (
      <a href={csv} className="btn btn-simple export-csv-btn">
        {I18n.t('users.export_as_csv')}
      </a>
    );
  }
}

CSVExportButton.propTypes = {
  csv: PropTypes.string.isRequired,
  classExportType: PropTypes.string.isRequired
};

function mapStateToProps(state, ownProps) {
  const { classExportType } = ownProps;
  let csv = '';
  csv = Selectors.getUsersCsvUrl(state);
  switch (classExportType) {
    case 'user':
      csv = Selectors.getUsersCsvUrl(state);
      break;
    case 'team':
    default:
      csv = Selectors.getTeamsCsvUrl(state);
  }

  return { csv };
}

export default connect(mapStateToProps)(CSVExportButton);
