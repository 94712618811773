import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TeamRole } from '@socrata/core-teams-api';

import ReduxState from '../reducers/teamsReducer.js';

import I18n from 'common/i18n';
import DropdownButton, { DropdownItem } from 'common/components/DropdownButton';
import DomainRights from 'common/types/domainRights';
import { currentUserHasRight } from 'common/current_user';

import * as Actions from '../actions';
import * as Selectors from '../../selectors.js';

interface OwnProps {
  displayName: string;
  teamRole: string;
  teamId: string;
  userId: string;
  ownerCount: number;
}

interface StateProps {
  teamName: string;
}

interface DispatchProps {
  removeTeamMember: (removeTeamMember: string, teamName: string) => void;
  changeMemberRole: (teamId: string, userId: string, proposedRole: TeamRole) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

export class MemberEditControl extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleRemoveTeamMember = (teamId: string, userId: string, displayName: string) => {
    const { removeTeamMember, teamName } = this.props;
    if (window.confirm(I18n.t('users.edit_team.confirm_remove_team_member', { displayName, teamName }))) {
      removeTeamMember(teamId, userId);
    }
  };

  handleChangeMemberRole = (teamId: string, userId: string, teamRole: string, proposedRole: TeamRole) => {
    const { changeMemberRole } = this.props;
    if (
      window.confirm(I18n.t('users.actions.confirm.change_role', { currentRole: teamRole, proposedRole }))
    ) {
      changeMemberRole(teamId, userId, proposedRole);
    }
  };

  render() {
    const { teamId, userId, teamRole, displayName, ownerCount } = this.props;
    const canEdit = currentUserHasRight(DomainRights.manage_teams);
    const isLastOwner = teamRole === TeamRole.Owner && ownerCount === 1;
    const makeOwnerButton = (
      <DropdownItem onClick={() => this.handleChangeMemberRole(teamId, userId, teamRole, TeamRole.Owner)}>
        {I18n.t('users.edit_team.make_team_owner')}
      </DropdownItem>
    );

    const makeMemberButton = (
      <DropdownItem onClick={() => this.handleChangeMemberRole(teamId, userId, teamRole, TeamRole.Member)}>
        {I18n.t('users.edit_team.make_team_member')}
      </DropdownItem>
    );

    return (
      <DropdownButton isDisabled={isLastOwner || !canEdit}>
        <DropdownItem onClick={() => this.handleRemoveTeamMember(teamId, userId, displayName)}>
          {I18n.t('users.edit_team.remove_team_member')}
        </DropdownItem>
        {teamRole === TeamRole.Owner ? makeMemberButton : makeOwnerButton}
      </DropdownButton>
    );
  }
}

const mapStateToProps = (state: typeof ReduxState, { teamId }: OwnProps): StateProps => ({
  teamName: teamId ? Selectors.getTeamNameById(state, teamId) : ''
});

const mapDispatchToProps = {
  removeTeamMember: Actions.removeTeamMember,
  changeMemberRole: Actions.changeMemberRole
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberEditControl);
