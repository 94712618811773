export const LOAD_PLATFORM_ADMINS = 'LOAD_PLATFORM_ADMINS';
export const LOAD_PLATFORM_ADMINS_SUCCESS = 'LOAD_PLATFORM_ADMINS_SUCCESS';
export const LOAD_PLATFORM_ADMINS_FAILURE = 'LOAD_PLATFORM_ADMINS_FAILURE';

export const loadPlatformAdmins = () => ({ type: LOAD_PLATFORM_ADMINS });
export const loadPlatformAdminsSuccess = (platformAdminGrants) => ({
  type: LOAD_PLATFORM_ADMINS_SUCCESS,
  payload: { platformAdminGrants }
});
export const loadPlatformAdminsFailure = (error) => ({
  type: LOAD_PLATFORM_ADMINS_FAILURE,
  error: true,
  payload: { error }
});

export const SHOW_ADD_PLATFORM_ADMIN_ACCESS_MODAL = 'SHOW_ADD_PLATFORM_ADMIN_ACCESS_MODAL';
export const HIDE_ADD_PLATFORM_ADMIN_ACCESS_MODAL = 'HIDE_ADD_PLATFORM_ADMIN_ACCESS_MODAL';
export const CANCEL_ADD_PLATFORM_ADMIN_ACCESS_MODAL = 'CANCEL_ADD_PLATFORM_ADMIN_ACCESS_MODAL';
export const SUBMIT_ADD_PLATFORM_ADMIN_ACCESS_MODAL = 'SUBMIT_ADD_PLATFORM_ADMIN_ACCESS_MODAL';
export const showAddPlatformAdminAccessModal = () => ({ type: SHOW_ADD_PLATFORM_ADMIN_ACCESS_MODAL });
export const hideAddPlatformAdminAccessModal = () => ({ type: HIDE_ADD_PLATFORM_ADMIN_ACCESS_MODAL });
export const cancelAddPlatformAdminAccessModal = () => ({ type: CANCEL_ADD_PLATFORM_ADMIN_ACCESS_MODAL });
export const submitAddPlatformAdminAccessModal = () => ({ type: SUBMIT_ADD_PLATFORM_ADMIN_ACCESS_MODAL });

export const GRANT_ADMIN_ACCESS_SUCCESS = 'GRANT_ADMIN_ACCESS_SUCCESS';
export const grantAdminAccessSuccess = (grant) => ({ type: GRANT_ADMIN_ACCESS_SUCCESS, payload: { grant } });

export const GRANT_ADMIN_ACCESS_FAILURE = 'GRANT_ADMIN_ACCESS_FAILURE';
export const grantAdminAccessFailure = (error) => ({ type: GRANT_ADMIN_ACCESS_FAILURE, payload: { error } });

export const CHANGE_PLATFORM_ADMIN_EMAIL = 'CHANGE_PLATFORM_ADMIN_EMAIL';
export const changePlatformAdminEmail = (email) => ({
  type: CHANGE_PLATFORM_ADMIN_EMAIL,
  payload: { email }
});

export const CHANGE_ACCESS_TIME = 'CHANGE_ACCESS_TIME';
export const changeAccessTime = (accessTime) => ({ type: CHANGE_ACCESS_TIME, payload: { accessTime } });

export const REVOKE_ADMIN_ACCESS = 'REVOKE_ADMIN_ACCESS';
export const revokeAdminAccess = (id) => ({ type: REVOKE_ADMIN_ACCESS, payload: { id } });

export const REVOKE_ADMIN_ACCESS_SUCCESS = 'REVOKE_ADMIN_ACCESS_SUCCESS';
export const revokeAdminAccessSuccess = (id) => ({ type: REVOKE_ADMIN_ACCESS_SUCCESS, payload: { id } });
