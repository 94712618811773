import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const Datetime = (props) => {
  const { timestamp, unknownLabel } = props;
  const createdAtMoment = moment.utc(timestamp, 'X');
  const x = new Date();
  const label = createdAtMoment.isValid()
    ? createdAtMoment.utcOffset(-x.getTimezoneOffset()).format('LLLL Z')
    : unknownLabel;
  return <span title={label}>{label}</span>;
};

Datetime.propTypes = {
  timestamp: PropTypes.number,
  unknownLabel: PropTypes.string
};

Datetime.defaultProps = {
  unknownLabel: ''
};

export default Datetime;
