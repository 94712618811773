import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import Notification from './components/Notification';
import TabbedPanelInterface from 'common/components/TabbedPanelInterface/TabbedPanelInterface';
import TabbedPanel from 'common/components/TabbedPanelInterface/TabbedPanel';
import * as Selectors from './selectors';
import Users from './users/components/Users';
import UsersRoutingTab from './users/components/UsersRoutingTab';
import InvitedUsers from './invitedUsers/components/InvitedUsers';
import InvitedUsersRoutingTab from './invitedUsers/components/InvitedUsersRoutingTab';
import Teams from './teams/components/Teams';
import TeamsRoutingTab from './teams/components/TeamsRoutingTab';
import TeamView from './teams/components/TeamView';
import PlatformAdminAccess from './platformAdminAccess/components/PlatformAdminAccess';
import PlatformAdminAccessRoutingTab from './platformAdminAccess/components/PlatformAdminAccessRoutingTab';

export const App = ({ store }) => (
  <Provider store={store}>
    <div className="admin-users-app">
      <Notification />
      <div className="header-button-bar" />
      <TabbedPanelInterface store={store} basePath="/admin" defaultPathSelector={Selectors.getUsersAdminPath}>
        <TabbedPanel
          component={Users}
          isVisibleSelector={Selectors.getShowUsers}
          tabComponent={UsersRoutingTab}
          pathSelector={Selectors.getUsersAdminPath}
        />
        <TabbedPanel
          component={InvitedUsers}
          isVisibleSelector={Selectors.getShowUsers}
          tabComponent={InvitedUsersRoutingTab}
          pathSelector={Selectors.getInvitedUsersAdminPath}
        />
        <TabbedPanel
          component={Teams}
          tabComponent={TeamsRoutingTab}
          pathSelector={Selectors.getTeamsAdminPath}
          isVisibleSelector={Selectors.getShowTeams}
        />
        <TabbedPanel
          component={TeamView}
          pathSelector={Selectors.getTeamViewPath(':teamId')}
          isVisibleSelector={Selectors.getShowTeams}
        />
        <TabbedPanel
          component={PlatformAdminAccess}
          tabComponent={PlatformAdminAccessRoutingTab}
          pathSelector={Selectors.getPlatformAdminAccessPath}
          isVisibleSelector={Selectors.getShowPlatformAdminAccess}
        />
      </TabbedPanelInterface>
    </div>
  </Provider>
);

App.propTypes = {
  store: PropTypes.object.isRequired
};

export default App;
