import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';

/** Generic error for when we don't know what's going on */
const GenericError: FunctionComponent = () => (
  <div
    dangerouslySetInnerHTML={{
      __html: I18n.t('users.actions.delete_team_info.unknown_error')
    }}
  />
);

export default GenericError;
