import PropTypes from 'prop-types';

export const RolePropType = PropTypes.shape({
  defaultSortOrder: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isDefault: PropTypes.bool,
  name: PropTypes.string,
  numberOfFutureAccounts: PropTypes.number,
  numberOfUsers: PropTypes.number,
  rights: PropTypes.arrayOf(PropTypes.string),
  translationKey: PropTypes.string
});
